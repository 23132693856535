import { LIVE_STAGE } from 'constants/room'
export default {
  // 直播间阶段
  liveStage: LIVE_STAGE.NOT_STARTED,
  // 房间号信息
  roomId: null,
  // 直播间昵称
  roomName: '',
  // 用户信息
  userInfo: {
    userId: '',
    userName: '',
    userAvatar: '',
    userRoles: []
  },
  // 直播间信息
  liveInfo: {},
  // 用户登录信息
  isLogin: false,
  // 是否在屏幕分享中
  isScreenSharing: false,
  // 使用的摄像头设备
  activeCamera: {},
  // 使用的麦克风设备
  activeMicrophone: {},
  // 使用的扬声器设备
  activeSpeaker: {},
  // 本地流是否设置镜像
  isSetMirror: true,
  // 视频参数
  videoProfile: {
    width: 1280,
    height: 720,
    frameRate: 30,
    bitrate: 750
  },
  // 是否开启美颜
  isOpenBeauty: false,
  // 美颜参数
  beautyParam: {
    contrast: 0,
    brightness: 0,
    saturation: 0
  },
  // 是否开启录制
  isRecordLive: true,
  // 音频采集状态
  isAudioMuted: false,
  // 视频采集状态
  isVideoMuted: false,
  // 音量大小
  audioLevel: 50,
  // 上行网络质量等级
  uplinkQualityLevel: 0,
  // 推流地址
  pushUrl: '',
  // 视频流ID
  videoStreamId: '',
  // 音频流ID
  audioStreamId: '',
  // 屏幕分享流ID
  screenStreamId: '',
  // 虚机信息
  vmInfo: null,
  // 实训信息
  sxInfo: null
}
