<template lang="pug">
  item-card.help-container(title="学员列表")
    div.help-list
      el-button.btn-clear(type="primary" size="mini" @click="clearHelp")
        i.el-icon-delete &nbsp;清空提问
      ul(v-if="helpList.length>0")
        li(v-for="item in helpList" :key="item.id")
          p
            span.name {{item.name}}
            el-tag.live(:type="item.isLive=='Y'?'success':'info'" size="mini") {{item.isLive=='Y'?'实训中':'已离线'}}
          el-popover(placement="right-start" width="300" trigger="hover" v-model="item.showPop")
            el-button.close(icon="el-icon-close" circle type="danger" size="mini" @click="hideContent(item)")
            ul.c-list
              li(v-for="cItem in item.handUpParamItems" :key="cItem.id")
                p {{cItem.content}}
                p.time {{getMessageTime(cItem.launchDate)}}
            el-button.process(slot="reference" :type="item.isProcess=='N'?'danger':'info'" :plain="item.isProcess!='N'" size="small" @click="showContent(item)")
              svg-icon(icon-name="question")
              span 求助
          div.vm-list.clearfix(v-if="item.sxList && item.sxList.vmList.length > 0")
            el-button(v-for="(vmItem, vmIndex) in item.sxList.vmList" :key="vmItem.vmId" type="text" @click="openVm(vmItem, item)") {{vmItem.formatName}}
</template>

<script>
import itemCard from '@/components/common/item-card'
import { MessageBox } from 'element-ui'
import { mapGetters, mapState } from 'vuex'
import { UPDATE_VM_INFO } from 'constants/mutation-types'
export default {
  name: 'HelpList',
  components: {
    itemCard
  },
  data() {
    return {
      getHandUpListsUrl: '/msg/api-saas/getHandUpList',
      cleanUpHandUpUrl: '/msg/api-saas/cleanUpHandUp',
      processHandUpUrl: '/msg/api-saas/processHandUp',
      helpList: [],
      updateHelpList: null
    }
  },
  mounted() {
    document.title = '学员求助 - 华盾播训在线学习平台'
  },
  computed: {
    ...mapGetters(['sxCode']),
    ...mapState(['roomId'])
  },
  watch: {
    roomId: {
      immediate: true,
      handler(val) {
        if (!val) return false
        const self = this
        self.getHelpList()
        self.updateHelpList = setInterval(() => {
          self.getHelpList()
        }, 3000)
      }
    }
  },
  beforeDestroy() {
    clearInterval(this.updateHelpList)
  },
  methods: {
    async getHelpList() {
      const self = this
      self.$axios.post(self.getHandUpListsUrl, { 'roomKey': self.roomId, 'sxCode': self.sxCode }).then((res) => {
        if (res.data.success) {
          const list = res.data.result
          list.forEach((item, index) => {
            item.showPop = false
            self.sortJson(item.handUpParamItems, 'launchDate', 'desc')
            if (item.sxList) {
              self.formatName(item.sxList.vmList)
            }
          })
          self.helpList = list
        } else {
          clearInterval(this.updateHelpList)
          if (res.data.message || res.data.msg) {
            self.$message.error(res.data.message || res.data.msg)
          }
        }
      })
    },
    openVm(vm, sx) {
      const self = this
      const currentVm = vm
      currentVm.username = sx.name
      currentVm.usertoken = (sx.isLive === 'Y') ? sx.messageToken : null
      self.$store.commit(UPDATE_VM_INFO, currentVm)
    },
    clearHelp() {
      MessageBox.confirm('确定要清空提问吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const self = this
        self.$axios.post(self.cleanUpHandUpUrl, { 'roomKey': this.roomId }).then((res) => {
          if (res.data.success) {
            self.$message.success('清空提问成功！')
            self.helpList = []
          } else {
            if (res.data.message || res.data.msg) {
              self.$message.error(res.data.message || res.data.msg)
            }
          }
        })
      }).catch(() => {})
    },
    getMessageTime(time) {
      let month = (new Date(time).getMonth()) + 1
      let day = new Date(time).getDate()
      let hour = new Date(time).getHours()
      let minute = new Date(time).getMinutes()
      month = month >= 10 ? month.toString() : `0${month}`
      day = day >= 10 ? day.toString() : `0${day}`
      hour = hour >= 10 ? hour.toString() : `0${hour}`
      minute = minute >= 10 ? minute.toString() : `0${minute}`
      return `${month}-${day} ${hour}:${minute}`
    },
    sortJson(array, key, order) {
      const newArray = array.sort(function(index, next) {
        var a = index[key]; var b = next[key]
        if (order === 'asc') {
          return ((a < b) ? -1 : ((a > b) ? 1 : 0))
        } else {
          return ((a > b) ? -1 : ((a < b) ? 1 : 0))
        }
      })
      array = newArray
    },
    formatName(array) {
      if (!array) return false
      array.forEach((item, index) => {
        item.formatName = item.formatName || '虚拟机' + (index + 1)
      })
    },
    showContent(item) {
      item.showPop = true
    },
    hideContent(item) {
      item.showPop = false
      const self = this
      self.$axios.post(self.processHandUpUrl, { 'id': item.id }).then((res) => {
        if (res.data.success) {
          item.isProcess = 'Y'
        } else {
          if (res.data.message || res.data.msg) {
            self.$message.error(res.data.message || res.data.msg)
          }
        }
      })
    }
  }
}
</script>

<style lang="stylus" scoped>
  ::-webkit-scrollbar
    background transparent
    width 8px
  ::-webkit-scrollbar-thumb
    border-radius 10px
    background rgba(lightgray, 0.3)
    transition 0.3s ease-in-out

  .help-container
    height 100%
    padding-top 8px
    position relative
    .btn-clear
      color white
      padding 7px
      position absolute
      right 20px
      top 12px
    .help-list
      height calc(100% - 48px)
      overflow-y auto
      padding 0 8px
      text-align left
      li
        border-bottom 1px solid rgba(184,184,184,0.1)
        padding 20px 10px
        position relative
        .process
          position absolute
          right 10px
          top 18px
          line-height 10px
          padding 5px 8px
          svg,svg + span
            vertical-align middle
          svg
            margin-right 5px
        .live
          margin-left 10px
        &:last-child
          border none
    .vm-list
      margin-top 15px
      button
        color white
        border-color white
        font-size 16px
        float left
        padding 7px
        margin-bottom 10px
        &:nth-of-type(even)
          float right
        &:nth-of-type(odd)
          clear both

  .el-button+.el-button
    margin-left 0 !important
</style>
