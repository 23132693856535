<!--
 * @Description: 推流设置弹窗
-->
<template lang="pug">
  div.push-setting
    div.push-select
      el-form(:model="pushForm" ref="pushForm" :rules="rules" hide-required-asterisk)
        el-form-item(label="推流地址" prop="setPushUrl")
          el-input(v-model="pushForm.setPushUrl" clearable placeholder="webrtc://")
</template>

<script>
import { mapState } from 'vuex'
import {
  UPDATE_PUSH_URL
} from 'constants/mutation-types'
export default {
  name: 'CompAudioSetting',
  data() {
    return {
      rules: {
        setPushUrl: [
          { required: true, message: '请输入推流地址', trigger: 'blur' }
        ]
      },
      pushForm: {
        setPushUrl: ''
      }
    }
  },
  computed: {
    ...mapState({
      pushUrl: 'pushUrl'
    })
  },
  watch: {
    pushUrl: {
      immediate: true,
      handler(val) {
        this.pushForm.setPushUrl = val
        this.$nextTick(() => {
          this.$refs['pushForm'].validate((valid) => {})
        })
      }
    },
    'pushForm.setPushUrl'(val) {
      let protocol = val.split('://')
      val = val.replace(protocol[0], 'webrtc')
      this.$store.commit(UPDATE_PUSH_URL, val)
    }
  },
  created() {},
  methods: {}
}
</script>

<style lang="stylus" scoped>
.push-setting
  padding 0 20px 20px 34px
  >>>.el-form-item__label
    color #fff
    display inline-block
    font-size 14px
  >>>.el-input
    display block
    width 390px
</style>

