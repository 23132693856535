<template>
  <div v-loading="loading" class="vnc-wrapper" :element-loading-text="loadingText" element-loading-background="#202020">
    <!-- <el-button class="bt-setting" type="primary" icon="el-icon-s-tools" circle @click="setting=true" /> -->
    <div :id="id" class="vnc-vm" />
    <!-- <el-drawer title="操作列表" :visible.sync="setting" direction="rtl" size="200px" :modal-append-to-body="false">
      <h3>解锁</h3>
      <el-button type="primary" @click="cad">Ctrl + Alt + Delete</el-button>
      <el-divider />
      <el-button type="primary" @click="restart">强制重启</el-button>
      <el-button type="primary" @click="remake">重建</el-button>
    </el-drawer> -->
  </div>
</template>

<script>
import RFB from '@novnc/novnc/core/rfb'
export default {
  name: 'NoVNC',
  props: {
    id: String,
    code: String,
    url: String
  },
  data() {
    return {
      loading: true,
      setting: false,
      width: '',
      loadingText: '虚拟机连接中',
      rfb: null,
      reStartUrl: '/hdcd/exam/reStart',
      reMakeServerUrl: '/hdcd/exam/reMakeServer',
      lockReconnect: false,
      timeoutnum: null
    }
  },
  watch: {
    url: {
      handler(newVal, oldVal) {
        this.rfb = null
        this.connectVnc()
      }
    }
  },
  created() {
    this.$nextTick(() => {
      this.connectVnc()
    })
  },
  methods: {
    // 连接vnc的函数
    connectVnc() {
      document.getElementById(this.id).innerHTML = ''
      const rfb = new RFB(document.getElementById(this.id), this.url, {})
      rfb.addEventListener('connect', this.connectedToServer)
      rfb.addEventListener('disconnect', this.disconnectedFromServer)
      rfb.scaleViewport = true // scaleViewport指示是否应在本地扩展远程会话以使其适合其容器。禁用时，如果远程会话小于其容器，则它将居中，或者根据clipViewport它是否更大来处理。默认情况下禁用。
      // rfb.resizeSession = true; //是一个boolean指示是否每当容器改变尺寸应被发送到调整远程会话的请求。默认情况下禁用
      this.rfb = rfb
    },
    disconnect() {
      this.lockReconnect = true
      document.getElementById(this.id).innerHTML = ''
      this.rfb.disconnect()
    },
    // 连接成功的回调函数
    connectedToServer() {
      this.loading = false
      this.loadingText = '虚拟机连接中'
    },

    disconnectedFromServer(msg) {
      var _this = this
      // clean是boolean指示终止是否干净。在发生意外终止或错误时 clean将设置为 false。
      // if (msg.detail.clean) {
      //   // 根据 断开信息的msg.detail.clean 来判断是否可以重新连接
      // } else {
      //   // 这里做不可重新连接的一些操作
      //   // console.log('虚拟机意外终止或错误')
      // }
      if (this.lockReconnect || !msg.detail.clean) {
        return false
      }
      this.lockReconnect = true
      // 没连接上会一直重连，设置延迟避免请求过多
      this.timeoutnum && clearTimeout(this.timeoutnum)
      this.timeoutnum = setTimeout(function() {
        // 新连接
        _this.rfb = null
        _this.connectVnc()
        _this.lockReconnect = false
      }, 5000)
    },

    // 用户操作
    cad() { // 解锁
      this.rfb.sendCtrlAltDel()
      this.setting = false
    },
    restart() { // 重启
      const self = this
      const postData = {
        vmId: self.code
      }
      self.$axios.post(self.reStartUrl, postData).then((res) => {
        if (res.data.success) {
          self.loadingText = '虚拟机重启中'
          self.loading = true
          self.setting = false
        } else {
          if (res.data.message || res.data.msg) {
            self.$message.error(res.data.message || res.data.msg)
          }
        }
      })
    },
    remake() { // 重建
      const self = this
      const postData = {
        vmId: self.code,
        vmName: self.id
      }
      self.$axios.post(self.reMakeServerUrl, postData).then((res) => {
        if (res.data.success) {
          self.loadingText = '虚拟机重做中'
          self.loading = true
          self.setting = false
        } else {
          if (res.data.message || res.data.msg) {
            self.$message.error(res.data.message || res.data.msg)
          }
        }
      })
    }
  }
}
</script>

<style scoped lang="stylus">
  .vnc-wrapper
    height 100%
    .vnc-vm
      height 100%
    .bt-setting
      position absolute
      right 10px
      top 10px
      opacity 0.4
      &:hover
        opacity 1
    >>>.el-drawer
      text-align center
      h3
        margin-bottom 5px
      .el-drawer__header>:first-child
        font-size 16px

  //重置loading控件
  >>>.el-loading-mask
    .el-loading-spinner
      margin-top -71px
      svg.circular
        display none !important
      .el-loading-text::before
          content ''
          display block
          width 99px
          height 100px
          margin:0 auto 15px auto
          background url('/loading.gif')
          background-size: cover
    .el-loading-text
      color #fff
</style>
