<template lang="pug">
  el-dialog.dialog-style-title(
    :visible.sync="showBeautyDialog"
    :width="width"
    :before-close="handleClose"
    class="dialog-style"
    :modal-append-to-body="false"
    :append-to-body="false"
    :modal="false"
    :close-on-click-modal="false"
  )
    div.dialog-title(slot="title") 滤镜设置
    div.dialog-content
      div.beauty-content
        div.beauty-item.beauty
          span.desc 对比度
          el-slider.slider-style(v-model="beautyInfo.contrast" :min="-100" :max="100")
        div.beauty-item.brightness
          span.desc 亮度
          el-slider.slider-style(v-model="beautyInfo.brightness" :min="-100" :max="100")
        div.beauty-item.ruddy
          span.desc 饱和度
          el-slider.slider-style(v-model="beautyInfo.saturation" :min="-100" :max="100")
      el-checkbox.mirror-checkbox(v-model="currentMirror" :disabled="liveStage !== LIVE_STAGE.NOT_STARTED") 镜像
    div.dialog-footer(slot="footer")
      el-button(type="primary" @click="handleLastStep") {{ $t('common.Next') }}
</template>

<script>
import { mapState } from 'vuex'
import {
  UPDATE_SET_MIRROR,
  UPDATE_BEAUTY_PARAM
} from 'constants/mutation-types'
import {
  LIVE_STAGE
} from 'constants/room'
import DeviceSelect from '@/components/common/device-select'
export default {
  name: 'RoomBeautyDialog',
  components: {
    DeviceSelect
  },
  data() {
    return {
      width: '',
      beautyInfo: {
        contrast: 0,
        brightness: 0,
        saturation: 0
      },
      showBeautyDialog: false,
      currentMirror: true,
      LIVE_STAGE,
      videoEffectManager: this.$livePusher.getVideoEffectManager()
    }
  },
  computed: {
    ...mapState({
      beautyParam: 'beautyParam',
      isSetMirror: 'isSetMirror',
      liveStage: 'liveStage',
      videoStreamId: 'videoStreamId'
    })
  },
  watch: {
    isSetMirror: {
      immediate: true,
      handler(val) {
        this.currentMirror = val
      }
    },
    currentMirror(val) {
      this.$store.commit(UPDATE_SET_MIRROR, val)
      this.videoEffectManager.setMirror({
        streamId: this.videoStreamId,
        mirrorType: val ? 1 : 0
      })
    },
    beautyParam: {
      immediate: true,
      handler(val) {
        this.beautyInfo = val
      }
    },
    beautyInfo: {
      handler(val) {
        this.$store.commit(UPDATE_BEAUTY_PARAM, val)
        this.videoEffectManager.setNormalFilter({
          streamId: this.videoStreamId,
          contrast: val.contrast,
          brightness: val.brightness,
          saturation: val.saturation
        })
      },
      deep: true
    }
  },
  created() {
    this.beautyInfo = this.beautyParam
    this.currentMirror = this.isSetMirror
  },
  mounted() {
    this.handleResize()
    window.addEventListener('resize', this.handleResize)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    handleShowBeautyDialog() {
      this.showBeautyDialog = true
    },
    handleLastStep() {
      this.showBeautyDialog = false
      this.$emit('lastStep')
    },
    handleClose() {
      this.showBeautyDialog = false
    },
    handleResize() {
      this.width = `${document.getElementById('stream').offsetWidth}px`
    }
  }
}
</script>

<style lang="stylus" scoped>
.dialog-title
  font-weight bold
  color $fontColor
  font-size 16px
.dialog-content
  text-align left
  .beauty-content
    padding 0 10px
    display flex
    justify-content space-between
    margin-bottom 16px
    .beauty-item
      display flex
      flex-direction column
      align-items flex-start
      width 30%
      .desc
        font-weight bold
        // color $fontColor
        font-size 16px
        display inline-block
        margin 0 20px 0 0
      .slider-style
        width calc(100% - 60px)
  .mirror-checkbox
    margin 0 0 10px 10px
.dialog-footer
  width 100%
  height 100%
  text-align right

.dialog-style-title
  text-align left
  & >>> .el-dialog
    position absolute
    left 300px
    bottom 60px
    margin 0 auto
</style>

<i18n>
{
	"en": {
    "Beauty Filter": "Beauty Filter",
    "Save": "Save"
	},
	"zh": {
		"Beauty Filter": "美颜设置",
    "Save": "完成设置"
	}
}
</i18n>
