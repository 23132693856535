import 'assets/style/global.css'

import Vue from 'vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App'
import router from './router/index.js'
import store from '@/store'
import i18n from '@/locales/i18n'
import eventBus from 'utils/_eventBus.js'
import 'assets/icons'
import axios from 'axios'
import ls from 'utils/storage'
import TXLivePusher from 'utils/TXLivePusher'
import splitPane from 'vue-splitpane'

// document.title = i18n.t('title.pusher');
document.title = '教师端直播间'

Vue.use(ElementUI);

Vue.component('split-pane', splitPane)

Vue.prototype.$eventBus = eventBus

Vue.prototype.$axios = axios
Vue.prototype.$ls = ls
Vue.prototype.$livePusher = new TXLivePusher()

Vue.config.productionTip = false

new Vue({
  i18n,
  store,
  router,
  render: h => h(App)
}).$mount('#app')
