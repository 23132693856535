<template lang="pug">
  div.vm-container
    template(v-if="currentVmList")
      <NoVNC v-for="(item, index) in currentVmList" v-show="activeVmIndex == index" :id="item.vmName" :key="item.vmName" ref="vmItem" :code="item.vmId" :url="item.vmUrl" />
</template>

<script>
import NoVNC from '@/components/NoVNC'
import { mapGetters } from 'vuex'
export default {
  name: 'VmPreviewList',
  components: {
    NoVNC
  },
  props: {
    activeVmIndex: {
      type: Number,
      default: -1
    }
  },
  data() {
    return {
      loading: true,
      getVmUrlUrl: '/hdcd/exam/getVnc',
      currentVmList: null
    }
  },
  computed: {
    ...mapGetters(['sxCode', 'vmList'])
  },
  watch: {
    vmList: {
      immediate: true,
      async handler(val) {
        if (!val) return false
        const self = this
        self.currentVmList = []
        for (let i = 0; i < val.length; i++) {
          if (val[i].vmId) {
            val[i].vmUrl = await this.getVmUrl(val[i].vmId)
            self.currentVmList.push(val[i])
          }
        }
      }
    }
  },
  created() {
    this.$eventBus.$on('vmCad', this.cad)
    this.$eventBus.$on('vmRestart', this.restart)
    this.$eventBus.$on('vmRemake', this.remake)
  },
  beforeDestroy() {
    this.$eventBus.$off('vmCad')
    this.$eventBus.$off('vmRestart')
    this.$eventBus.$off('vmRemake')
  },
  methods: {
    getVmUrl(vmId) {
      const self = this
      return new Promise((resolve, reject) => {
        self.$axios.post(self.getVmUrlUrl, { vmId }).then((res) => {
          if (res.data.success && res.data.result) {
            let vmUrl = res.data.result
            vmUrl = vmUrl.replace('https://novnc.hdproskills.com', 'wss://novnc.hdproskills.com:7682')
            vmUrl = vmUrl.replace('vnc_auto.html?path=%3Ftoken%3D', '?token=')
            resolve(vmUrl)
          }
        })
      })
    },
    cad() {
      if (this.activeVmIndex > -1) {
        this.$refs.vmItem[this.activeVmIndex].cad()
      }
    },
    restart() {
      if (this.activeVmIndex > -1) {
        this.$confirm('您确定要现在重启当前环境吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async() => {
          let vmUrl = await this.getVmUrl(this.currentVmList[this.activeVmIndex].vmId)
          vmUrl = vmUrl.replace('https://novnc.hdproskills.com', 'wss://novnc.hdproskills.com:7682')
          vmUrl = vmUrl.replace('vnc_auto.html?path=%3Ftoken%3D', '?token=')
          this.currentVmList[this.activeVmIndex].vmUrl = vmUrl
          this.$refs.vmItem[this.activeVmIndex].restart()
        }).catch(() => {})
      }
    },
    remake() {
      if (this.activeVmIndex > -1) {
        this.$confirm('您确定要现在重做当前环境吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async() => {
          let vmUrl = await this.getVmUrl(this.currentVmList[this.activeVmIndex].vmId)
          vmUrl = vmUrl.replace('https://novnc.hdproskills.com', 'wss://novnc.hdproskills.com:7682')
          vmUrl = vmUrl.replace('vnc_auto.html?path=%3Ftoken%3D', '?token=')
          this.currentVmList[this.activeVmIndex].vmUrl = vmUrl
          this.$refs.vmItem[this.activeVmIndex].remake()
        }).catch(() => {})
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
  .vm-container
    height 100%
    position relative
    .vm-info
      opacity 0.35
      position absolute
      left 20px
      top 20px
      z-index 3000
    .btn-close
      position absolute
      right 20px
      top 20px
      z-index 3000

  //重制empty控件
  >>>.el-empty
    background-color #202020
    height 100%
    .el-empty__description p
      color white

</style>
