<template lang="pug">
  div.vm-container(v-loading="loading" element-loading-text="请选择虚拟机" element-loading-background="#202020")
    template(v-if="currentVm")
      NoVNC(:id="currentVm.vmName" ref="vmItem" :code="currentVm.vmId" :url="currentVm.vmUrl")
      el-tag.vm-info {{currentVm.username + ' - ' + currentVm.formatName}}
      el-button.btn-close(size="small" @click="closeVm")
        i.el-icon-delete &nbsp;退出远程
</template>

<script>
import NoVNC from '@/components/NoVNC'
import { mapGetters, mapState } from 'vuex'
import { UPDATE_VM_INFO } from 'constants/mutation-types'
import Socket from '@/utils/websocket'
export default {
  name: 'VmPreview',
  components: {
    NoVNC
  },
  data() {
    return {
      loading: true,
      getVmUrlUrl: '/hdcd/exam/getVnc',
      getMessageTokenUrl: '/msg/api-saas/getMessageToken',
      socketUrl: '/message/exchange/',
      teacherToken: '',
      userToken: '',
      ws: null,
      currentVm: null
    }
  },
  computed: {
    ...mapGetters(['sxCode']),
    ...mapState(['vmInfo','roomId'])
  },
  watch: {
    vmInfo: {
      immediate: true,
      async handler(val) {
        if (!val) return false
        const self = this
        if (self.currentVm) {
          self.$message.error('请先退出当前虚拟机！')
        } else {
          val.vmUrl = await self.getVmUrl(val.vmId)
          self.currentVm = val
          self.$store.commit(UPDATE_VM_INFO, val)
          self.loading = false
          if(val.usertoken && self.teacherToken){
            self.userToken = val.usertoken
            self.initWebSocket()
          }
        }
      }
    }
  },
  mounted() {
    this.getUid()
  },
  methods: {
    getVmUrl(vmId) {
      const self = this
      return new Promise((resolve, reject) => {
        self.$axios.post(self.getVmUrlUrl, { vmId }).then((res) => {
          if (res.data.success && res.data.result) {
            let vmUrl = res.data.result
            vmUrl = vmUrl.replace('https://novnc.hdproskills.com', 'wss://novnc.hdproskills.com:7682')
            vmUrl = vmUrl.replace('vnc_auto.html?path=%3Ftoken%3D', '?token=')
            resolve(vmUrl)
          }
        })
      })
    },
    // 获取聊天室临时token
    getUid() {
      const self = this
      self.$axios.post(self.getMessageTokenUrl).then((res) => {
        if (res.data.success) {
          self.teacherToken = res.data.result
        }
      })
    },
    // 初始化weosocket
    initWebSocket() {
      const self = this
      const wsuri = this.socketUrl + this.roomId + '/' + this.teacherToken + '/' + this.userToken
      self.ws = new Socket()
      self.ws.setUrl(wsuri)
      self.ws.setType('exchange')
      self.ws.init()
      setTimeout(function() {
        self.ws.send('openVm')
      }, 500);
    },
    closeVm() {
      if (this.$refs.vmItem) {
        this.$refs.vmItem.disconnect()
      }
      this.currentVm = null
      this.$store.commit(UPDATE_VM_INFO, null)
      this.loading = true
      if(this.ws){
        this.ws.send('closeVm')
        this.ws.onclose()
        this.ws = null
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
  .vm-container
    height 100%
    position relative
    .vm-info
      opacity 0.35
      position absolute
      left 20px
      top 20px
      z-index 3000
    .btn-close
      position absolute
      right 20px
      top 20px
      z-index 3000

  //重制empty控件
  >>>.el-empty
    background-color #202020
    height 100%
    .el-empty__description p
      color white

</style>
