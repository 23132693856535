import CryptoJS from 'crypto-js'
var key = 'j~x!j@y_20￥21'

export default {
  encryp: function(data) {
    if (typeof data === "object") {
      // 如果传入的data是json对象，先转义为json字符串
      try {
        data = JSON.stringify(data)
      } catch (error) {
        console.log("error:", error)
      }
    }
    // 统一将传入的字符串转成UTF8编码
    const dataHex = CryptoJS.enc.Utf8.parse(data) // 需要加密的数据
    const keyHex = CryptoJS.enc.Utf8.parse(key) // 秘钥
    const encrypted = CryptoJS.RC4.encrypt(dataHex, keyHex)
    let encryptedVal = encrypted.ciphertext.toString()
    return encryptedVal //  返回加密后的值
  },
  decrypt: function(encryptedVal) {
    /*
      传入的key和iv需要和加密时候传入的key一致
    */
    // 统一将传入的字符串转成UTF8编码
    let encryptedHexStr = CryptoJS.enc.Hex.parse(encryptedVal);
    let srcs = CryptoJS.enc.Base64.stringify(encryptedHexStr);
    const keyHex = CryptoJS.enc.Utf8.parse(key) // 秘钥
    let decrypt = CryptoJS.RC4.decrypt(srcs, keyHex);
    let decryptedStr = decrypt.toString(CryptoJS.enc.Utf8);
    return decryptedStr.toString();
  }
}
