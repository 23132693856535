<!--
 * @Description: 设备选择弹窗（摄像头，麦克风，扬声器）
 * props:
 *   deviceType: String 'microphone'|'speaker'|'camera'
 *   onChange: Function 监听select的 change 事件的执行函数
 *   disabled: 是否可选择，默认值为false
 * @Date: 2021-10-27 17:20:31
 * @LastEditTime: 2021-10-28 22:13:08
-->

<template lang="pug">
  el-select.select(
    v-model="activeDeviceId"
    :disabled="disabled"
    :placeholder="placeholder")
    el-option(
      v-for="item in deviceList"
      :key="item.deviceId"
      :label="item.deviceName"
      :value="item.deviceId"
    )
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import {
  UPDATE_ACTIVE_CAMERA,
  UPDATE_ACTIVE_MICROPHONE
} from 'constants/mutation-types'
export default {
  name: 'DeviceSelect',
  props: {
    deviceType: String,
    onChange: Function,
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      deviceList: [],
      activeDeviceId: '',
      relation: {
        video: this.$t('video'),
        audio: this.$t('audio')
      },
      relationId: {
        video: this.activeVideoId,
        audio: this.activeAudioId
      },
      relationStream: {
        video: this.videoStreamId,
        audio: this.audioStreamId
      },
      deviceManager: this.$livePusher.getDeviceManager()
    }
  },
  computed: {
    ...mapGetters(['activeAudioId', 'activeVideoId']),
    ...mapState({
      videoStreamId: 'videoStreamId',
      audioStreamId: 'audioStreamId'
    }),
    placeholder() {
      return this.$t('Select a', [this.relation[this.deviceType]])
    }
  },
  watch: {
    isSetMirror: {
      immediate: true,
      handler(val) {
        this.currentMirror = val
      }
    },
    activeDeviceId(val) {
      const device = this.deviceList.find(device => device.deviceId === val)
      if (!device) {
        return false
      }
      switch (this.deviceType) {
        case 'video':
          this.$store.commit(UPDATE_ACTIVE_CAMERA, device)
          this.deviceManager.switchCamera(val, this.videoStreamId).then()
          break
        case 'audio':
          this.$store.commit(UPDATE_ACTIVE_MICROPHONE, device)
          this.deviceManager.switchMicrophone(val, this.audioStreamId).then()
          break
        default:
          break
      }
    }
  },
  created() {
    navigator.mediaDevices.addEventListener('devicechange', async() => {
      await this.getDeviceList()
    })
    this.getDeviceList()
  },
  beforeDestroy() {
    navigator.mediaDevices.removeEventListener('devicechange', this.getDeviceList)
  },
  methods: {
    getDeviceList() {
      this.deviceManager.getDevicesList(this.deviceType).then((data) => {
        if (data.length > 0 && data[0].deviceId !== '') {
          this.deviceList = data
          const device = data.find(device => device.deviceId === this.relationId[this.deviceType])
          this.activeDeviceId = device ? device.deviceId : data[0].deviceId
        } else {
          this.$message.error('请授权' + this.relation[this.deviceType] + '的访问权限')
          this.deviceList = []
          this.activeDeviceId = ''
        }
      })
    }
  }
}
</script>

<style lang="stylus" scoped>
.select
  width 300px
  margin-left 20px
  margin-bottom 10px
</style>

<i18n>
{
	"en": {
		"video": "camera",
		"audio": "mic",
    "Select a": "Select a {0}"
	},
	"zh": {
    "video": "摄像头",
		"audio": "麦克风",
    "Select a": "请选择{0}"
	}
}
</i18n>
