<!--
 * @Description: 麦克风设置弹窗
 * @Date: 2021-10-27 18:13:37
 * @LastEditTime: 2021-11-09 15:47:10
-->
<template lang="pug">
  div.audio-setting
    div.microphone-select
      span.title {{ $t('device.Mic') }}
      el-select.select-style(v-model="choseMicrophoneId" :placeholder="$t('device.Select a mic')")
        el-option(
          v-for="item in microphoneList"
          :key="item.deviceId"
          :label="item.deviceName"
          :value="item.deviceId"
        )
    div.microphone-volume
      span.title 音量
      el-slider.select-style(v-model="microphoneVolume" :disabled="choseMicrophoneId===''")
</template>

<script>
import {
  UPDATE_ACTIVE_MICROPHONE,
  UPDATE_AUDIO_LEVEL
} from 'constants/mutation-types'
import { mapGetters, mapState } from 'vuex'
export default {
  name: 'CompAudioSetting',
  data() {
    return {
      choseMicrophoneId: '',
      microphoneList: [],
      microphoneVolume: 50,
      deviceManager: this.$livePusher.getDeviceManager(),
      audioEffectManager: this.$livePusher.getAudioEffectManager()
    }
  },
  computed: {
    ...mapGetters(['activeAudioId']),
    ...mapState({
      audioStreamId: 'audioStreamId',
      audioLevel: 'audioLevel'
    })
  },
  watch: {
    choseMicrophoneId(val) {
      const choseMicrophoneDevice = this.microphoneList.find(item => item.deviceId === val)
      if (!choseMicrophoneDevice) {
        return false
      }
      this.$store.commit(UPDATE_ACTIVE_MICROPHONE, choseMicrophoneDevice)
      this.deviceManager.switchMicrophone(val, this.audioStreamId).then(() => {
        this.microphoneVolume = this.audioLevel
      })
    },
    microphoneVolume(val) {
      this.$store.commit(UPDATE_AUDIO_LEVEL, val)
      this.audioEffectManager.setVolume(val, this.audioStreamId)
    }
  },
  mounted() {
    navigator.mediaDevices.addEventListener('devicechange', async() => {
      await this.getDeviceList()
    })
    this.getDeviceList()
  },
  beforeDestroy() {
    navigator.mediaDevices.removeEventListener('devicechange', this.getDeviceList)
  },
  methods: {
    async getDeviceList() {
      this.deviceManager.getDevicesList('audio').then((data) => {
        if (data.length > 0 && data[0].deviceId !== '') {
          this.microphoneList = data
          const device = data.find(device => device.deviceId === this.activeAudioId)
          this.choseMicrophoneId = device ? device.deviceId : data[0].deviceId
        } else {
          this.$message.error('请授权麦克风的访问权限')
          this.microphoneList = []
          this.choseMicrophoneId = ''
        }
      })
    }
  }
}
</script>

<style lang="stylus" scoped>
.audio-setting
  padding 0 20px 20px 34px
  .title
    display inline-block
    width 60px
  .select-style
    display inline-block
    width 320px
    margin-left 10px
    margin-bottom 10px
    vertical-align middle
</style>

