<template lang="pug">
item-card.card-container(:title="$t('Chat')")
  span.nc {{nc}}
    svg-icon(icon-name="user")
  template(v-if="uid!==''")
    //- 聊天区域
    div.content-top-chat(ref="box")
      div.out(v-if="messageList.length === 0") {{ $t('Chat now') }}
      div.single-message(v-for="message, index in messageList" :class="{'isMine':message.isMine}")
        template(v-if="message.type === 'normal'")
          div.message-info
            span.user-name {{message.nick}}
            span.message-time {{getMessageTime(message.time)}}
          div.message-content
            //- 文字消息
            span {{message.message }}
        template(v-else-if="message.type === 'illegal'")
          div.message-content.illegal
            //- 违规提醒
            span {{message.message }}
    div.divider

    //- 文字输入区域
    div.content-bottom
      //- 文字输入
      div.content-bottom-input
        input.input(
          ref="input"
          type="text"
          v-model="inputMsg"
          :placeholder="$t('Type a message')"
          @keyup.enter="sendMessage"
          :disabled="isLiveEnded"
          required="required"
        )
        span.send-button(@click="sendMessage") {{ $t('Send') }}
  template(v-else)
    p.error 缺少课程信息<br>无法链接聊天室
</template>

<script>
import itemCard from '@/components/common/item-card'
import Socket from '@/utils/websocket'
import { LIVE_STAGE } from 'constants/room'
import { mapState } from 'vuex'
export default {
  name: 'CompChat',
  components: {
    itemCard
  },
  data() {
    return {
      inputMsg: '',
      getMessageTokenUrl: '/msg/api-saas/getMessageToken',
      socketUrl: '/message/livingWb/',
      uid: '',
      assistant: '',
      ws: null,
      nc: 0,
      messageList: []
    }
  },
  computed: {
    ...mapState({
      liveStage: 'liveStage',
      roomId: 'roomId'
    }),
    isLiveEnded() {
      return this.liveStage === LIVE_STAGE.ENDED
    }
  },
  watch: {
    // 发出一条新消息，自动到最底部
    messageList() {
      this.$nextTick(() => {
        const msg = this.$refs.box
        msg.scrollTop = msg.scrollHeight
      })
    }
  },
  mounted() {
    this.getUid()
  },
  beforeDestroy() {
  },
  methods: {
    // 获取聊天室临时token
    getUid() {
      const self = this
      self.$axios.post(self.getMessageTokenUrl).then((res) => {
        if (res.data.success) {
          self.uid = res.data.result
          self.initWebSocket()
        }
      })
    },
    initWebSocket() {
      // 初始化weosocket
      const wsuri = this.socketUrl + this.roomId + '/' + this.uid
      this.ws = new Socket()
      this.ws.setUrl(wsuri)
      this.ws.setFn('message', this.websocketonmessage)
      this.ws.init()
    },
    websocketonmessage(e) { // 数据接收
      if (!this.ws.ifJson(e.data)) {
        return false
      }
      var redata = JSON.parse(e.data)
      switch (redata.ty) {
        case 'normal':
          this.pushMessage(redata)
          this.ws.resetHeart()
          break
        case 'recall':
          this.recallMessage(redata.cid)
          break
        case 'illegal':
          this.pushNotice(redata)
          break
        case 'nc':
          this.nc = redata.cn
          break
        default:
          break
      }
    },
    sendMessage() {
      if (this.uid === '') {
        return false
      }
      if (this.inputMsg === '' || /^\s+$/gi.test(this.inputMsg)) {
        this.inputMsg = ''
        this.$message.warning('发送的内容不能为空！')
      } else {
        const processMessage = {
          'cn': this.inputMsg.trim(),
          'ty': 'normal'
        }
        this.ws.send(processMessage)
        this.inputMsg = ''
      }
    },
    pushMessage(redata) {
      var processMessage = {
        'message': redata.cn,
        'messageId': redata.mi,
        'type': redata.ty,
        'nick': redata.un,
        'isMine': redata.fn === this.uid,
        'time': redata.mt
      }
      this.messageList.push(processMessage)
    },
    pushNotice(redata) {
      var message = {
        'message': redata.cn,
        'type': redata.ty,
        'time': redata.mt
      }
      this.messageList.push(message)
    },
    recallMessage(id) {
      const msg = this.messageList.findIndex(item => {
        if (item.messageId === id) {
          return true
        }
      })
      if (msg > -1) {
        this.messageList.splice(msg, 1)
      }
    },
    getMessageTime(time) {
      let hour = new Date(time).getHours()
      let minute = new Date(time).getMinutes()
      hour = hour >= 10 ? hour.toString() : `0${hour}`
      minute = minute >= 10 ? minute.toString() : `0${minute}`
      return `${hour}:${minute}`
    }
  }
}
</script>

<style lang="stylus" scoped>
.card-container
  width 100%
  height 100%
  display flex
  flex-direction column
  position relative
.error
  color #888
  line-height 2
  margin 30vh auto
.nc
  color #DDD
  font-size 14px
  position absolute
  top 13px
  right 20px
  .svg-icon
    float left
    height 12px
    width 12px
    margin-right 5px
    margin-top 3px
    vertical-align middle
.content-top-chat
  flex-grow 1
  width 100%
  margin 10px 0
  overflow auto
  margin 10px 0
  border-radius 10px
  padding 14px 10px
  color $fontColor
  font-size 14px
  .out
    color #999
    margin 30vh auto 0 auto
    text-align center
  .single-message
    width 100%
    text-align left
    margin-bottom 15px
    .message-info
      height 30px
      line-height 30px
      color #DDD
      font-size 14px
      .user-name
        padding-right 12px
      .message-time
        color #999 !important
        visibility hidden
    .message-content
      width auto
      display inline-block
      background-color rgba(223,223,223,0.05)
      padding 5px 12px
      border-radius 4px
      font-size 16px
      word-break break-all
      span
        display inline-block
        vertical-align center
    &:hover .message-time
      visibility visible
    &.isMine
      .message-info *
        color #649bff
    .illegal
      text-align center
      background-color rgba(255, 255, 255, 0.75)
      span
        font-size 12px
        color #FF0000 !important
        line-height 1.5
        margin 0 auto
        width auto
.divider
  width 100%
  height 2px
  background-color $lineColor

.content-bottom
  width 100%
  padding 12px
  div.content-bottom-feel
    width 100%
    text-align left
    .icon-button
      cursor pointer
      svg
        fill $fontColor
    .smile-icon
      display inline-block
      width 30px
      height 30px
  div.content-bottom-input
    text-align left
    position relative
    margin 4px auto 0
    .input
      color $fontColor
      border-radius 5px
      top 0
      right 0
      width 100%
      height 42px
      padding-left 13px
      padding-right 70px
      background-color $backgroundColor
      border none
      outline none
    .send-button
      height 42px
      line-height 42px
      position absolute
      right 12px
      cursor pointer
      color $fontColor
      font-size 14px
</style>

<i18n>
{
	"en": {
		"Chat": "Chat",
    "Chat now": "Chat now",
    "Type a message": "Type a message",
    "Send": "Send"
	},
	"zh": {
		"Chat": "互动消息",
    "Chat now": "快来互动吧",
    "Type a message": "说点什么",
    "Send": "发送"
	}
}
</i18n>

