<template lang="pug">
  div#app
    div#header
      comp-header
    template(v-if="isLogin")
      div#content(v-loading="submitQuestionLoading" :element-loading-text="submitLoadingText" element-loading-background="#202020")
        split-pane(:default-percent="leftPaneWidth" split="vertical")
          template(slot="paneL")
            comp-sx(:textSourceDataList="textSourceDataList")
            div.collapseContainer
              el-button(type="text" @click="collapse")
                span(v-if="leftPaneWidth>0") 收起
                  i.el-icon-s-fold
                span(v-else) 目录
                  i.el-icon-s-unfold
          template(slot="paneR")
            el-container
              el-main
                comp-sx-list(v-if="!showVm")
                comp-sx-vm(v-else :activeVmIndex="activeVmIndex")
              el-aside.actionContainer(:class="{'hidden':!showAction}")
                comp-sx-control(v-if="sxInfo" :activeVmIndex="activeVmIndex")
                div.collapseContainer.right
                  el-button(v-if="!showAction" type="text" @click="showAction = true") 操作
                    i.el-icon-s-fold
                  el-button(v-else type="text" @click="showAction = false") 收起
                    i.el-icon-s-fold
    comp-login(v-else)
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import compHeader from '@/components/comp-header'
import compLogin from '@/components/comp-login'
import compSxVm from '@/components/comp-sx/vm'
import compChat from '@/components/comp-chat'
import compSx from '@/components/comp-sx'
import compSxList from '@/components/comp-sx/list'
import compSxControl from '@/components/comp-sx/control'
export default {
  name: 'Sx',
  components: {
    compHeader,
    compLogin,
    compSxVm,
    compChat,
    compSx,
    compSxList,
    compSxControl
  },
  data() {
    return {
      leftPaneWidth: (400 / document.body.clientWidth) * 100,
      qCode: this.$route.query.qCode,
      showAction: false,
      showVm: false,
      submitQuestionLoading: false,
      submitLoadingText: '正在退出中...',
      activeVmIndex: -1,
      textSourceDataList: [],
      getSxQuestionDetailUrl: '/hdcd/exam/getSxQuestionDetail',
      extendExaminationOperateUrl: '/hdcd/exam/extendExaminationOperate'
    }
  },
  computed: {
    ...mapState(['isLogin', 'vmInfo', 'sxInfo']),
    ...mapGetters(['sxCode', 'token'])
  },
  mounted() {
    this.getQuestionDetail()
    this.$eventBus.$on('showVmAction', this.showVmAction)
    this.$eventBus.$on('showVmList', this.showVmList)
    this.$eventBus.$on('showVmIndex', (index) => {
      this.showVmIndex(index)
    })
    this.$eventBus.$on('updateLoading', (loading) => {
      this.updateLoading(loading)
    })
  },
  beforeDestroy() {
    this.$eventBus.$off('showVmAction')
    this.$eventBus.$off('showVmList')
    this.$eventBus.$off('showVmIndex')
    this.$eventBus.$off('updateLoading')
  },
  methods: {
    // 获取题目详情
    getQuestionDetail() {
      const self = this
      self.$axios.post(self.getSxQuestionDetailUrl, { 'examCode': self.sxCode, 'questionCode': self.qCode }).then((res) => {
        if (res.data.success) {
          const result = res.data.result
          result.textSourceDataList.unshift({
            content: result.stem,
            textSourceName: result.questionName,
            sourceType: 'text'
          })
          self.textSourceDataList = result.textSourceDataList.filter((item) => {
            return item.sourceType === 'text'
          })
        } else {
          if (res.data.message || res.data.msg) {
            self.$message.error(res.data.message || res.data.msg)
          }
        }
      })
    },
    collapse() {
      if (this.leftPaneWidth > 0) {
        this.leftPaneWidth = 0
      } else {
        this.leftPaneWidth = (400 / document.body.clientWidth) * 100
      }
    },
    showVmList() {
      this.showVm = true
    },
    showVmAction() {
      this.showAction = true
    },
    showVmIndex(index) {
      this.activeVmIndex = index
    },
    updateLoading(loading) {
      this.submitQuestionLoading = loading
    }
  }
}
</script>

<style lang="stylus" scoped>
  #app
    text-align left

  //显示隐藏按钮
  .collapseContainer
    background #FFFFFF
    border-radius 0px 4px 4px 0px
    cursor pointer
    position absolute
    top calc((100% - 50px - 86px) / 2)
    right -28px
    text-align center
    user-select none
    z-index 100
    height 86px
    width 28px
    &.right
      border-radius 4px 0px 0px 4px !important
      left -28px !important
    >>>.el-button
      color #202020
      font-size 14px
      font-weight 400
      height 100%
      line-height 1.3
      padding 0
      white-space normal
    [class^="el-icon-"],
    [class*=" el-icon-"]
      color rgba(0, 0, 0, 0.4)
      font-size 17px
      margin-top 4px

  //右侧操作栏
  .actionContainer
    background-color #fff
    height 100%
    padding 20px 25px
    overflow visible
    position relative
    width 180px !important
    &.hidden
      padding 0
      width 0 !important

  // 重置vue-splitter控件
  >>>.vue-splitter-container
    width 100%
    .splitter-pane.vertical.splitter-paneL
      padding-right 0 !important

    .splitter-pane-resizer.vertical
      z-index 1980 !important

    .splitter-pane.vertical.splitter-paneR
      padding-left 0 !important

  // 重置el-container控件
  >>>.el-container
    height 100%
    overflow hidden

    .el-header
      padding 0
      position relative
      overflow hidden

    .el-main
      padding 0
</style>
