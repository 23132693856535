<template lang="pug">
  item-card(:title="$t('Streaming Settings')")
    template
      div.button-container
        icon-button(
          :text="$t('Audio')"
          @click="handleAudioSetting")
          svg-icon(icon-name="mic")
        icon-button(
          :text="$t('Video')"
          @click="handleVideoSetting")
          svg-icon(icon-name="camera")
        icon-button(
          text="滤镜设置"
          @click="handleBeautySetting")
          svg-icon(icon-name="beauty")
        icon-button(
          text="推流设置"
          @click="handlePushSetting")
          svg-icon(icon-name="video-connect")

    el-dialog.dialog-style-title(
      :title="$t('Streaming Settings')"
      :visible.sync="dialogVisible"
      width="600px"
      :before-close="handleDialogClose"
      class="live-setting-dialog"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    )
      el-tabs.tabs-style(
        tab-position="left"
        style="height: 430px;"
        v-model="activeTab"
         @tab-click="handleTabClick")
        //- 音频设置
        el-tab-pane(:label="$t('Audio')" name="audio")
          audio-setting(:activeTab="activeTab")
        //- 视频设置
        el-tab-pane(:label="$t('Video')" name="video")
          video-setting(:activeTab="activeTab")
        //- 滤镜设置
        el-tab-pane(:label="$t('Beauty Filter')" name="beauty")
          beauty-setting(:activeTab="activeTab")
        //- 推流设置
        el-tab-pane(label="推流设置" name="push")
          push-setting(:activeTab="activeTab")
</template>

<script>
import itemCard from '@/components/common/item-card'
import iconButton from '@/components/common/icon-button'
import audioSetting from './audio-setting'
import videoSetting from './video-setting'
import beautySetting from './beauty-setting'
import pushSetting from './push-setting'
export default {
  name: 'LiveSetting',
  components: {
    itemCard,
    iconButton,
    audioSetting,
    videoSetting,
    beautySetting,
    pushSetting
  },
  data() {
    return {
      dialogVisible: false,
      activeTab: '',
      cameraList: [],
      choseCameraId: '',
      microphoneList: [],
      choseMicrophoneId: '',
      speakerList: [],
      choseSpeakerId: '',
      isOpenBeauty: true
    }
  },
  computed: {
  },
  created() {
  },
  mounted() {
  },
  beforeDestroy() {
    this.handleDialogClose()
  },
  methods: {
    handleAudioSetting() {
      this.dialogVisible = true
      this.activeTab = 'audio'
    },
    handleVideoSetting() {
      this.dialogVisible = true
      this.activeTab = 'video'
    },
    handleBeautySetting() {
      this.dialogVisible = true
      this.activeTab = 'beauty'
    },
    handlePushSetting() {
      this.dialogVisible = true
      this.activeTab = 'push'
    },
    handleTabClick(tab) {
      this.activeTab = tab.name
    },
    handleDialogClose() {
      this.dialogVisible = false
      this.activeTab = ''
    }
  }
}
</script>

<style lang="stylus" scoped>
.button-container
  position relative
  display flex
  flex-wrap wrap
  padding 20px 8px 0 8px

.live-setting-dialog
  text-align left
</style>

<i18n>
{
	"en": {
		"Streaming Settings": "Streaming Settings",
		"Audio": "Audio",
		"Video": "Video",
    "Beauty Filter": "Beauty Filter",
    "Others": "Others"
	},
	"zh": {
		"Streaming Settings": "快速开播",
		"Audio": "音频设置",
		"Video": "视频设置",
    "Beauty Filter": "美颜设置",
    "Others": "其他设置"
	}
}
</i18n>
