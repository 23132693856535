export default {
  setLocal: function(key, value) {
    localStorage.setItem(key, value)
  },

  getLocal: function(key) {
    if (this.exists(key)) {
      return localStorage.getItem(key)
    } else {
      return null
    }
  },

  exists: function(key) {
    return localStorage.hasOwnProperty(key)
  },

  delLocal: function(key) {
    localStorage.removeItem(key)
  },

  clear: function() {
    localStorage.clear()
  },

  getParam: function(name) {
    var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i')
    var r = window.location.search.substr(1).match(reg)
    if (r != null) return unescape(r[2])
    return null
  },

  storageValid(key) {
    var date = new Date()
    date = date.getTime()
    var expire = this.getLocal(key + '-expire')
    if (date < Number(expire)) {
      return true
    }
    this.delLocal(key)
    this.delLocal(key + '-expire')
    return false
  }
}
