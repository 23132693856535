<template lang="pug">
  item-card.help-container(title="学员列表")
    div.help-list
      ul(v-if="monitorList.length>0")
        li(v-for="item in monitorList" :key="item.id")
          p
            span.name {{item.name}}
            el-tag.live(:type="item.isLive=='Y'?'success':'info'" size="mini") {{item.isLive=='Y'?'实训中':'已离线'}}
          div.vm-list.clearfix(v-if="item.vmList && item.vmList.length > 0")
            el-button(v-for="(vmItem, vmIndex) in item.vmList" :key="vmItem.vmId" type="text" @click="openVm(vmItem, item.name)") {{vmItem.formatName}}
</template>

<script>
import itemCard from '@/components/common/item-card'
import { mapGetters, mapState } from 'vuex'
import { UPDATE_VM_INFO } from 'constants/mutation-types'
export default {
  name: 'MonitorList',
  components: {
    itemCard
  },
  data() {
    return {
      getSxMonitorListUrl: '/sx/saas-api/getSxMonitorList',
      monitorList: [],
      updateMonitorList: null
    }
  },
  mounted() {
    document.title = '全部学员 - 华盾播训在线学习平台'
  },
  computed: {
    ...mapGetters(['sxCode']),
    ...mapState(['roomId'])
  },
  watch: {
    roomId: {
      immediate: true,
      handler(val) {
        if (!val) return false
        const self = this
        self.getSxMonitorList()
        self.updateMonitorList = setInterval(() => {
          self.getSxMonitorList()
        }, 3000)
      }
    }
  },
  beforeDestroy() {
    clearInterval(this.updateMonitorList)
  },
  methods: {
    async getSxMonitorList() {
      const self = this
      self.$axios.post(self.getSxMonitorListUrl, { 'roomKey': self.roomId, 'sxCode': self.sxCode }).then((res) => {
        if (res.data.success) {
          const list = res.data.result
          list.forEach((item, index) => {
            if (item.vmList) {
              self.formatName(item.vmList)
            }
          })
          self.monitorList = list
        } else {
          clearInterval(this.updateMonitorList)
          if (res.data.message || res.data.msg) {
            self.$message.error(res.data.message || res.data.msg)
          }
        }
      })
    },
    openVm(vm, username) {
      const self = this
      const currentVm = vm
      currentVm.username = username
      self.$store.commit(UPDATE_VM_INFO, currentVm)
    },
    formatName(array) {
      if (!array) return false
      array.forEach((item, index) => {
        item.formatName = item.formatName || '虚拟机' + (index + 1)
      })
    }
  }
}
</script>

<style lang="stylus" scoped>
  ::-webkit-scrollbar
    background transparent
    width 8px
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: rgba(lightgray, 0.3);
    transition: 0.3s ease-in-out;
}
  .help-container
    height 100%
    padding-top 8px
    position relative
    .help-list
      height calc(100% - 48px)
      overflow-y auto
      padding 0 8px
      text-align left
      li
        border-bottom 1px solid rgba(184,184,184,0.1)
        padding 20px 10px
        position relative
        .process
          position absolute
          right 10px
          top 18px
          line-height 10px
          padding 5px 8px
          svg,svg + span
            vertical-align middle
          svg
            margin-right 5px
        .live
          margin-left 10px
        &:last-child
          border none
    .vm-list
      margin-top 15px
      button
        color white
        border-color white
        font-size 16px
        float left
        padding 7px
        margin-bottom 10px
        &:nth-of-type(even)
          float right
        &:nth-of-type(odd)
          clear both
</style>
