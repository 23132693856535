/*
 * @Description: vuex-getter
 * @Date: 2021-11-03 10:40:21
 * @LastEditTime: 2021-11-08 20:07:46
 */
export default {  
  // 当前使用的摄像头设备Id
  activeVideoId(state) {
    return state.activeCamera.deviceId
  },
  // 当前使用的麦克风设备Id
  activeAudioId(state) {
    return state.activeMicrophone.deviceId
  },
  // 当前使用的扬声器设备Id
  activeSpeakerId(state) {
    return state.activeSpeaker.deviceId
  },
  // 当前直播间信息
  livingCode(state) {
    return state.liveInfo.livingCode
  },
  livingType(state) {
    return state.liveInfo.type
  },
  sxCode(state) {
    return state.liveInfo.sxCode
  },
  token(state) {
    return state.liveInfo.token
  },
  // 当前实训信息
  vmList(state){
    return state.sxInfo.vmList
  },
  uqId(state){
    return state.sxInfo.id
  },
  recoveryTime(state){
    return state.sxInfo.recoveryTime / 1000
  }
}
