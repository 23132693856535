/*
 * @Description: mutation-types
 * @Date: 2021-10-28 19:08:05
 * @LastEditTime: 2021-11-08 20:07:24
 */
// 设置直播间信息
export const SET_LIVE_INFO = 'SET_LIVE_INFO'
// 更新房间阶段
export const UPDATE_LIVE_STAGE = 'UPDATE_LIVE_STAGE'
// 设置房间ID
export const SET_ROOM_ID = 'SET_ROOM_ID'
// 更新房间信息
export const UPDATE_ROOM_NAME = 'UPDATE_ROOM_NAME'
// 更新用户信息
export const UPDATE_USER_INFO = 'UPDATE_USER_INFO'
// 更新用户登录信息
export const UPDATE_IS_LOGIN = 'UPDATE_IS_LOGIN'
// 更新屏幕分享状态 (开始 ｜ 暂停)
export const UPDATE_IS_SCREEN_SHARING = 'UPDATE_IS_SCREEN_SHARING'
// 更新使用的摄像头设备
export const UPDATE_ACTIVE_CAMERA = 'UPDATE_ACTIVE_CAMERA'
// 更新使用的麦克风设备
export const UPDATE_ACTIVE_MICROPHONE = 'UPDATE_ACTIVE_MICROPHONE'
// 更新使用的扬声器设备
export const UPDATE_ACTIVE_SPEAKER = 'UPDATE_ACTIVE_SPEAKER'
// 更新是否设置本地流镜像
export const UPDATE_SET_MIRROR = 'UPDATE_SET_MIRROR'
// 更新视频Profile
export const UPDATE_VIDEO_PROFILE = 'UPDATE_VIDEO_PROFILE'
// 更新是否开启美颜
export const UPDATE_OPEN_BEAUTY = 'UPDATE_OPEN_BEAUTY'
// 更新美颜参数
export const UPDATE_BEAUTY_PARAM = 'UPDATE_BEAUTY_PARAM'
// 更新是否录制直播
export const UPDATE_RECORD_LIVE = 'UPDATE_RECORD_LIVE'
// 更新是否采集视频状态
export const UPDATE_VIDEO_STATE = 'UPDATE_VIDEO_STATE'
// 更新是否采集视频状态
export const UPDATE_AUDIO_STATE = 'UPDATE_AUDIO_STATE'
// 更新音量大小
export const UPDATE_AUDIO_LEVEL = 'UPDATE_AUDIO_LEVEL'
// 更新上行网络质量登记
export const UPDATE_UPLINK_NETWORK_LEVEL = 'UPDATE_UPLINK_NETWORK_LEVEL'
// 更新推流地址
export const UPDATE_PUSH_URL = 'UPDATE_PUSH_URL'
// 更新视频流ID
export const UPDATE_VIDEO_STREAM_ID = 'UPDATE_VIDEO_STREAM_ID'
// 更新音频流ID
export const UPDATE_AUDIO_STREAM_ID = 'UPDATE_AUDIO_STREAM_ID'
// 更新分享流ID
export const UPDATE_SCREEN_STREAM_ID = 'UPDATE_SCREEN_STREAM_ID'
// 更新虚机信息 
export const UPDATE_VM_INFO = 'UPDATE_VM_INFO'
// 更新实训信息 
export const UPDATE_SX_INFO = 'UPDATE_SX_INFO'