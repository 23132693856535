<template lang="pug">
  div.infoContainer
    h3 实训回收时间：
    p(:class="{'warning': recoveryT < 60 * 10}") {{ formatRrecoveryT }}
    el-button(:class="{'disable': recoveryT >= 60 * 10}" :disabled="recoveryT >= 60 * 10" type="primary" @click="extendExamination") 延长实训
    br
    template(v-if="vmList && vmList.length > 0")
      el-button(v-for="(item, index) in vmList" :key="item.vmName" :class="{'disable': activeVmIndex == index}" type="primary" @click="showVm(index)") {{ item.formatName }}
    br
    br
    el-button(type="primary" plain @click="cad" :disabled="activeVmIndex<0") Ctrl + Alt + Delete
    el-button(type="primary" plain @click="restart" :disabled="activeVmIndex<0") 重启
    el-button(type="primary" plain @click="remake" :disabled="activeVmIndex<0") 重做
    br
    el-button.submit(type="primary" @click="submit") 退出
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'SxControl',
  props: {
    activeVmIndex: {
      type: Number,
      default: -1
    }
  },
  data() {
    return {
      qCode: this.$route.query.qCode,
      extendExaminationOperateUrl: '/hdcd/exam/extendExaminationOperate',
      exitSxUrl: '/hdcd/exam/exitSxOperate',
      countDownRecoveryTime: null,
      recoveryT: null,
      formatRrecoveryT: null
    }
  },
  computed: {
    ...mapGetters(['sxCode', 'token', 'vmList', 'uqId', 'recoveryTime'])
  },
  watch: {
    recoveryTime: {
      immediate: true,
      async handler(val) {
        if (!val) return false
        this.recoveryT = val
        this.formatRrecoveryT = this.formatCountdown(val)
        this.getRecoveryTime()
      }
    }
  },
  methods: {
    // 每秒更新实训回收时间
    getRecoveryTime() {
      clearInterval(this.countDownRecoveryTime)
      this.countDownRecoveryTime = setInterval(() => {
        this.countRecoveryTime()
      }, 1000)
    },
    countRecoveryTime() {
      if (this.recoveryT > 0) {
        this.recoveryT--
        this.formatRrecoveryT = this.formatCountdown(this.recoveryT)
      } else {
        clearInterval(this.countDownRecoveryTime)
        this.$notify({
          title: '提示',
          message: '已到实训环境销毁时间，请稍后进入！',
          duration: 0,
          type: 'warning'
        })
        this.endExamProcess()
      }
    },
    formatCountdown(time) {
      const rh = this.addZero(Math.floor((time / 60 / 60) % 24))
      const rm = this.addZero(Math.floor((time / 60) % 60))
      const rs = this.addZero(Math.floor((time) % 60))
      return rh + ' : ' + rm + ' : ' + rs
    },
    addZero(time) {
      return (time.toString().length < 2) ? '0' + time : time
    },
    // 延长实训
    extendExamination() {
      if (this.recoveryT <= 60 * 10) {
        this.extendExaminationOperate()
      } else {
        this.$message.warning('实训回收暂未开启，请倒计时为十分钟内再试')
      }
    },
    extendExaminationOperate() {
      const self = this
      const postData = {
        userQuestionId: this.uqId,
        phone: this.token,
        questionCode: this.qCode
      }
      self.$axios.post(self.extendExaminationOperateUrl, postData).then((res) => {
        if (res.data.success) {
          self.$message.success('延长实训成功！')
          self.recoveryT = res.data.result
          self.formatRrecoveryT = this.formatCountdown(self.recoveryT)
        } else {
          if (res.data.message || res.data.msg) {
            self.$message.error(res.data.message || res.data.msg)
          }
        }
      })
    },
    showVm(index) {
      if (this.activeVmIndex === index) {
        this.$message.warning('您已开启此环境，无需重复操作')
        return false
      }
      this.$eventBus.$emit('showVmList')
      this.$eventBus.$emit('showVmIndex', index)
    },
    cad() {
      this.$eventBus.$emit('vmCad')
    },
    restart() {
      this.$eventBus.$emit('vmRestart')
    },
    remake() {
      this.$eventBus.$emit('vmRemake')
    },
    submit() {
      this.$confirm('您确定要现在退出本次实训吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.exitSx()
      }).catch(() => {})
    },
    exitSx() {
      const self = this
      const postData = {
        userQuestionId: self.uqId,
        questionCode: self.qCode
      }
      self.$eventBus.$emit('updateLoading', true)
      self.$axios.post(self.exitSxUrl, postData).then((res) => {
        self.$eventBus.$emit('updateLoading', false)
        if (res.data.success) {
          self.endExamProcess()
        } else {
          if (res.data.message || res.data.msg) {
            self.$message.error(res.data.message || res.data.msg)
          }
        }
      })
    },
    endExamProcess() {
      window.opener = null
      window.open('about:blank', '_top').close()
    }
  }
}
</script>

<style scoped lang="stylus">
//右侧操作栏
  .actionContainer
    background-color #fff
    height 100%
    padding: 20px 25px;
    overflow: visible;
    position: relative;
    width: 180px !important;
    &.hidden
      padding: 0;
      width: 0 !important;

    h3
      font-size: 18px;
      font-weight: 600;
      color: #202020;

    p
      font-size: 18px;
      color: #202020;
      margin-top: 10px;

      &.warning
        color: #EF4034;

    .infoContainer
      overflow: hidden;
      button.el-button--primary
        background-color: #4E6EF2;
        border-color: #4E6EF2;
        display: block;
        margin-top: 20px;
        margin-left: 0 !important;
        padding: 8px 0;
        width: 100%;
        &:hover
          opacity: 0.8;

        &.disable
          background-color: rgba(#202020, 0.1);
          border-color: rgba(#202020, 0.1);
          color: #999999;

        &.is-plain
          background-color: rgba(#4E6EF2, 0.2);
          border-color: rgba(#4E6EF2, 0.2);
          color: #4E6EF2;

        &.submit
          font-size: 20px;
          font-weight:bold;
          padding: 9px 0;

</style>
