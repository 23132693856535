import {
  Notification
} from 'element-ui'
  var Socket = function() {
    const timeout = 20 * 1000 // 20秒一次心跳
    // 私有变量
    let wsUrl = ''
    this.socket = null
    let onmessageFn = null
    let onopenFn = null
    let oncloseFn = null
    let onerrorFn = null
    let timeoutObj = null
    let timeoutnum = null
    let lockReconnect = false
    let isSilentSingle = false
    var type = ''
    const that = this

    // 设置websocket地址
    this.setUrl = function(url) {
      wsUrl = url
    }
    this.setType = function(val) {
      type = val
    }

    // 连接websocket
    this.init = function() {
      let apiUrl = ''
      // 生产
      switch (type){
        case 'exchange':
          apiUrl = 'wss://teacher.hdproskills.com/ws3'
          break;
        case 'system':
          apiUrl = 'wss://teacher.hdproskills.com/ws2'
          break;
        default:
          apiUrl = 'wss://teacher.hdproskills.com/ws1'
          break;
      }
      // 测试外网
      // apiUrl = isSystem ? 'wss://jxjy.goduck.top:9009':'wss://jxjy.goduck.top:9009'
      // 测试
      // apiUrl = isSystem ? 'ws://172.16.50.17:33033':'ws://172.16.50.17:33032'
      // 本地
      // apiUrl = isSystem ? 'ws://172.16.12.213:8899':'ws://172.16.12.213:8849'
     // switch (type){
     //    case 'exchange':
     //      apiUrl = 'ws://172.16.12.213:8809'
     //      break;
     //    case 'system':
     //      apiUrl = 'ws://172.16.12.213:8899'
     //      break;
     //    default:
     //      apiUrl = 'ws://172.16.12.213:8849'
     //      break;
     //  }
      this.socket = new WebSocket(apiUrl + wsUrl)
      this.socket.onmessage = this.onmessage
      this.socket.onopen = this.onopen
      this.socket.onclose = this.onclose
      this.socket.onerror = this.onerror
    }

    this.onmessage = function(e) {
      switch (e.data) {
        case 'pong': {
          that.resetHeart()
          break
        }
        case 'estoppel': {
          that.setSingleSilent()
          break
        }
        default: {
          if (!that.ifJson(e.data)) {
            return false
          }
          const redata = JSON.parse(e.data)
          if (redata.t === 'offlineAll') {
            that.offline()
          }
          break
        }
      }
      if (onmessageFn) {
        onmessageFn(e)
      }
    }

    this.onopen = function() {
      that.heart()
      if (onopenFn) {
        onopenFn()
      }
    }

    this.onclose = function() {
      timeoutObj = null
      clearTimeout(timeoutObj)
      that.socket.close()
      lockReconnect = true
      if (oncloseFn) {
        oncloseFn()
      }
    }

    this.onerror = function() {
      if (onerrorFn) {
        onerrorFn()
      }
    }

    //  重连
    this.reconnect = function() {
      if (lockReconnect) {
        return
      }
      lockReconnect = true
      // 没连接上会一直重连，设置延迟避免请求过多
      timeoutnum && clearTimeout(timeoutnum)
      timeoutnum = setTimeout(() => {
        // 新连接
        that.init()
        lockReconnect = false
      }, 5000)
    }

    // 心跳检测
    this.heart = function() {
      timeoutObj && clearTimeout(timeoutObj)
      timeoutObj = setTimeout(() => {
        // 这里发送一个心跳，后端收到后，返回一个心跳消息，
        if (that.socket.readyState === 1) { // 如果连接正常
          that.send('ping')
        } else { // 否则重连
          that.reconnect()
        }
      }, timeout)
    }

    // 重置心跳检测
    this.resetHeart = function() {
      clearTimeout(timeoutObj)
      that.heart()
    }

    // 发送消息
    this.send = function(message) {
      let msg = message
      if (isSilentSingle && msg !== 'ping') {
        Notification({
          title: '消息发送失败',
          message: '您已经被禁言',
          type: 'warning'
        })
        return false
      }
      if (that.socket.readyState !== 1) {
        Notification({
          title: '消息发送失败',
          message: '还未连接到聊天室，请稍后再试',
          type: 'error'
        })
        return false
      }
      if (typeof msg === 'object') {
        msg = JSON.stringify(msg)
      }
      that.socket.send(msg)
    }

    // 开启个人禁言
    this.setSingleSilent = function() {
      Notification({
        title: '系统通知',
        dangerouslyUseHTMLString: true,
        message: `<div>${that.formatDate(new Date())}</div><div>您已经被禁言！</div>`,
        type: 'warning',
        duration: 0
      })
      isSilentSingle = true
    }

    // 下线通知
    this.offline = function() {
      that.onclose()
      Notification({
        title: '系统通知',
        dangerouslyUseHTMLString: true,
        message: `<div>${that.formatDate(new Date())}</div><div>主播已下播，系统已自动将您踢出直播间！</div>`,
        type: 'warning',
        duration: 0
      })
    }

    // 自定义websocket方法
    this.setFn = function(method, fn) {
      switch (method) {
        case 'message':
          onmessageFn = fn
          break
        case 'open':
          onopenFn = fn
          break
        case 'close':
          oncloseFn = fn
          break
        case 'error':
          onerrorFn = fn
          break
        default:
          break
      }
    }

    // 验证是否是json
    this.ifJson = function(str) {
      if (typeof str === 'string') {
        try {
          JSON.parse(str)
          return true
        } catch (e) {
          return false
        }
      }
    }

    // 时间格式转换
    this.formatDate = function(shijianchuo) {
      // shijianchuo是整数，否则要parseInt转换
      const time = new Date(shijianchuo)
      const y = time.getFullYear()
      const m = time.getMonth() + 1
      const d = time.getDate()
      const h = time.getHours()
      const mm = time.getMinutes()
      const s = time.getSeconds()
      return `${y}-${that.add0(m)}-${that.add0(d)} ${that.add0(h)}:${that.add0(mm)}:${that.add0(s)}`
    }

    this.add0 = function(m) {
      return m < 10 ? `0${m}` : m
    }
  }
  export default Socket