<template lang="pug">
  div#app
    div#header
      comp-header
    template(v-if="isLogin")
      div#content
        div#left
          comp-all-list(v-if="type === 'all'")
          comp-help-list(v-else)
        div#center
          comp-help-vm
    comp-login(v-else)
</template>

<script>
import compHeader from '@/components/comp-header'
import compLogin from '@/components/comp-login'
import compHelpList from '@/components/comp-help/list'
import compAllList from '@/components/comp-help/all'
import compHelpVm from '@/components/comp-help/vm'
import { mapState } from 'vuex'
export default {
  name: 'App',
  components: {
    compHeader,
    compLogin,
    compHelpList,
    compAllList,
    compHelpVm
  },
  data() {
    return {
      type: this.$route.query.type
    }
  },
  computed: {
    ...mapState({
      isLogin: 'isLogin'
    })
  },
  mounted() {},
  beforeDestroy() {
  },
  methods: {
  }
}
</script>

