<!--
 * @Description: header区域
 * @Date: 2021-11-03 10:40:21
 * @LastEditTime: 2022-01-11 17:03:05
-->
<template lang="pug">
  div.pusher-title-container
    div.logo-container
      img.logo(:src="logoImg")
    div.right-container
      comp-user-info
</template>

<script>
import logoImg from 'assets/img/logo.png'
import compLanguage from './comp-language.vue'
import compUserInfo from './comp-user-info.vue'
import compExit from './comp-exit.vue'
export default {
  name: 'CompHeader',
  components: {
    compLanguage,
    compUserInfo,
    compExit
  },
  data() {
    return {
      logoImg
    }
  },
  computed: {},
  mounted() {
    document.title = this.$route.meta.title
  },
  methods: {}
}
</script>

<style lang="stylus" scoped>
.pusher-title-container
  width 100%
  height 100%
  position relative
  z-index 100
  display flex
  justify-content space-between
  align-items center
  padding 0 16px 0 0
  .logo-container
    height 100%
    font-size 18px
    display flex
    align-items center
    & > :not(:first-child)
      margin-left 10px
    .logo
      height 70%
      margin-left 10px
      vertical-align bottom
    .room-id
      display flex
      align-items center
      .copy-icon
        cursor pointer
        width 26px
        height 26px
  .right-container
    height 100%
    display flex
    align-items center
    & > :not(:first-child)
      margin-left 16px
</style>
