<template lang="pug">
  div#app
    div#header
      comp-header
    template(v-if="isLogin")
      div#content
        div#left.column
          comp-screen-share
          comp-live-setting
          comp-help(v-if="livingType=='sx'")
        div#center
          comp-live-stream
        div#right.column(v-if="livingType!='sx'")
          comp-chat
      room-device-dialog(ref="roomDeviceDialog" @lastStep="showPushPresettingDialog" @nextStep="showBeautyPresettingDialog")
      room-beauty-dialog(ref="roomBeautyDialog" @lastStep="showPushPresettingDialog")
      room-push-dialog(ref="roomPushDialog")
    comp-login(v-else)
</template>

<script>
import compHeader from '@/components/comp-header'
import compScreenShare from '@/components/comp-screen-share'
import compLiveSetting from '@/components/comp-live-setting/index.vue'
import compLiveStream from '@/components/comp-live-stream'
import compChat from '@/components/comp-chat'
import roomDeviceDialog from '@/components/comp-pre-setting/room-device-dialog.vue'
import roomBeautyDialog from '@/components/comp-pre-setting/room-beauty-dialog.vue'
import roomPushDialog from '@/components/comp-pre-setting/room-push-dialog.vue'
import compLogin from '@/components/comp-login'
import compHelp from '@/components/comp-help'
import { mapState, mapGetters } from 'vuex'
import { UPDATE_IS_LOGIN, UPDATE_IS_SCREEN_SHARING } from 'constants/mutation-types'
import TXLivePusher from 'utils/TXLivePusher'

export default {
  name: 'App',
  components: {
    compHeader,
    compScreenShare,
    compLiveSetting,
    compLiveStream,
    compChat,
    roomBeautyDialog,
    roomDeviceDialog,
    roomPushDialog,
    compLogin,
    compHelp
  },
  data() {
    return {
      infoUrl: '/lb/consumer/info',
      logoutUrl: '/lb/consumer/logout',
      supportMsg: {
        isWebRTCSupported: 'WebRTC',
        isH264EncodeSupported: 'H264编码',
        isH264DecodeSupported: 'H264解码',
        isMediaDevicesSupported: '获取媒体设备及媒体流',
        isScreenCaptureSupported: '屏幕采集',
        isMediaFileSupported: '获取本地媒体文件流'
      },
      videoEffectManager: this.$livePusher.getVideoEffectManager()
    }
  },
  computed: {
    ...mapState({
      isLogin: 'isLogin'
    }),
    ...mapGetters(['livingType'])
  },
  mounted() {
    this.checkSupport()
    this.setObserver()
  },
  async created() {
    this.$eventBus.$on('logout', this.handleLogout)
    this.$eventBus.$on('openPre', this.showDevicePresettingDialog)
    this.$eventBus.$on('openPush', this.showPushPresettingDialog)
  },
  beforeDestroy() {
    this.$eventBus.$off('logout', this.handleLogout)
    this.$eventBus.$off('openPre', this.showDevicePresettingDialog)
    this.$eventBus.$off('openPush', this.showPushPresettingDialog)
    if (this.$livePusher.isPushing()) {
      this.$livePusher.stopPush()
    }
  },
  methods: {
    // 检查浏览器支持性
    checkSupport() {
      TXLivePusher.checkSupport().then((data) => {
        const msg = []
        Object.keys(data).map((key, item) => {
          if (!data[key]) {
            msg.push(this.supportMsg[key])
          }
        })
        if (msg.length > 0) {
          this.$notify({
            title: '浏览器检测',
            message: '暂不支持以下功能：' + msg.join(', '),
            type: 'warning',
            duration: 0
          })
        } else {
          this.videoEffectManager.enableMixing(true)
        }
      })
    },
    // 显示设置预设置弹窗
    showDevicePresettingDialog() {
      this.$nextTick(() => {
        this.$refs.roomDeviceDialog.handleShowDeviceDialog()
      })
    },
    // 显示美颜预设置弹窗
    showBeautyPresettingDialog() {
      this.$refs.roomBeautyDialog.handleShowBeautyDialog()
    },
    // 显示推流地址预设置弹窗
    showPushPresettingDialog() {
      this.$refs.roomPushDialog.handleShowPushDialog()
    },
    // 退出登录
    async handleLogout() {
      const self = this
      const params = {
        token: self.$ls.getLocal('token')
      }
      self.$axios.post(self.logoutUrl, params).then((res) => {
        if (res.data.success && (res.data.result !== null || res.data.result !== {})) {
          self.$store.commit(UPDATE_IS_LOGIN, false)
          self.$ls.clear()
        } else {
          self.$store.commit(UPDATE_IS_LOGIN, false)
          self.$ls.clear()
        }
      })
    },
    setObserver() {
      const self = this
      self.$livePusher.setObserver({
        onWarning: function(code, message, data) {
          var WARNING_CODE = {
            CAMERA_INTERRUPTED: -1005, // 摄像头中断事件
            MICROPHONE_INTERRUPTED: -1006, // 麦克风中断事件
            SCREEN_INTERRUPTED: -1007 // 屏幕分享中断事件
          }
          if (code === WARNING_CODE.CAMERA_INTERRUPTED) {
            // 停止采集
            self.$message.error('摄像头中断，停止采集摄像头')
            self.$livePusher.stopCamera()
          }
          if (code === WARNING_CODE.MICROPHONE_INTERRUPTED) {
            // 停止采集
            self.$message.error('麦克风中断，停止采集麦克风')
            self.$livePusher.stopMicrophone()
          }
          if (code === WARNING_CODE.SCREEN_INTERRUPTED) {
            // 停止采集
            self.$message.error('屏幕分享中断，停止采集屏幕分享')
            self.$livePusher.stopScreenCapture()
            self.$store.commit(UPDATE_IS_SCREEN_SHARING, false)
          }
        },
        // connection status
        onPushStatusUpdate: function(status, message) {
          var STATUS_CODE = ['与服务器断开连接', '正在连接服务器', '连接服务器成功', '重连服务器中']
          console.log(STATUS_CODE[status] + '：' + message)
        }
      })
    }
  }
}
</script>

<style lang="stylus">
  .column
    display flex
    flex-direction column
    > div:not(:first-child)
      margin-top 8px
    > div:last-child
      flex-grow 1
</style>
