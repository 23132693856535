<!--
 * @Description: 美颜设置弹窗
 * @Date: 2021-10-27 18:13:37
 * @LastEditTime: 2021-11-09 15:47:16
-->
<template lang="pug">
  div.setting-item
    div.info 滤镜设置
    div#beauty-stream.local-stream-preview(:style="'-webkit-filter: contrast('+(beautyInfo.contrast + 100) +'%) brightness('+(beautyInfo.brightness + 100) +'%) saturate('+(beautyInfo.saturation + 100) +'%); filter: contrast('+(beautyInfo.contrast + 100)+'%) brightness('+(beautyInfo.brightness + 100)+'%) saturate('+(beautyInfo.saturation + 100)+'%);'")
    el-checkbox.beauty-checkbox(v-model="openBeauty") 开启滤镜
    div.beauty-item.beauty
      span.desc 对比度
      el-slider.slider-style(v-model="beautyInfo.contrast" :disabled="!openBeauty" :min="-100" :max="100")
    div.beauty-item.brightness
      span.desc 亮度
      el-slider.slider-style(v-model="beautyInfo.brightness" :disabled="!openBeauty" :min="-100" :max="100")
    div.beauty-item.ruddy
      span.desc 饱和度
      el-slider.slider-style(v-model="beautyInfo.saturation" :disabled="!openBeauty" :min="-100" :max="100")
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import {
  UPDATE_OPEN_BEAUTY,
  UPDATE_BEAUTY_PARAM
} from 'constants/mutation-types'
export default {
  name: 'CompBeautySetting',
  props: {
    activeTab: String
  },
  data() {
    return {
      openBeauty: false,
      beautyInfo: {
        contrast: 0,
        brightness: 0,
        saturation: 0
      },
      choseCameraId: '',
      deviceManager: this.$livePusher.getDeviceManager(),
      videoEffectManager: this.$livePusher.getVideoEffectManager(),
      rtcBeautyPlugin: null
    }
  },
  computed: {
    ...mapGetters(['activeVideoId']),
    ...mapState({
      beautyParam: 'beautyParam',
      isOpenBeauty: 'isOpenBeauty',
      videoStreamId: 'videoStreamId'
    })
  },
  watch: {
    isOpenBeauty: {
      immediate: true,
      handler(val) {
        this.openBeauty = val
      }
    },
    openBeauty(val) {
      this.$store.commit(UPDATE_OPEN_BEAUTY, val)
      if (val) {
        this.beautyInfo = this.beautyParam
      } else {
        this.beautyInfo = {
          contrast: 0,
          brightness: 0,
          saturation: 0
        }
      }
    },
    beautyParam: {
      immediate: true,
      handler(val) {
        this.beautyInfo = val
      }
    },
    beautyInfo: {
      handler(val) {
        this.$store.commit(UPDATE_BEAUTY_PARAM, val)
        this.videoEffectManager.setNormalFilter({
          streamId: this.videoStreamId,
          contrast: val.contrast,
          brightness: val.brightness,
          saturation: val.saturation
        })
      },
      deep: true
    },
    activeTab: {
      immediate: true,
      handler(val, oldVal) {
        if (val === 'beauty') {
          this.initBeautyVideo()
        } if (oldVal === 'beauty') {
          this.resetBeautyVideo()
        }
      }
    }
  },
  mounted() {
    this.videoEffectManager.enableMixing(true)
  },
  methods: {
    initBeautyVideo() {
      this.$nextTick(() => {
        const mediaStream = this.$livePusher.getMediaStream(this.videoStreamId)
        const node = document.getElementById('beauty-stream')
        var video = document.createElement('video')
        node.innerHTML = ''
        node.appendChild(video)
        if ('srcObject' in video) {
          video.srcObject = mediaStream
        } else {
          video.src = URL.createObjectURL(mediaStream)
        }
        video.onloadedmetadata = function(e) {
          video.play()
        }
      })
    },
    resetBeautyVideo() {
      const node = document.getElementById('beauty-stream')
      node.innerHTML = ''
    }
  }
}
</script>

<style lang="stylus" scoped>
.setting-item
  padding 10px 30px 20px 34px
  .info
    margin-bottom 10px
  .title
    display inline-block
    width 42px
  .local-stream-preview
    width fit-content
    height 186px
    border-radius 6px
    overflow hidden
    background-color #212126
    margin 0 auto 10px auto
    text-align center
    & >>> video
      max-height 100%
      max-width 100%
  .beauty-checkbox
    margin-bottom 10px
  .slider-style
    width 390px
</style>

<i18n>
{
	"en": {
		"Preview": "Preview",
		"Beauty filters": "Beauty filters"
	},
	"zh": {
		"Preview": "视频预览",
		"Beauty filters": "开启美颜"
	}
}
</i18n>
