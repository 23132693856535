<template lang="pug">
  div(class="list flex-wrapper flex-center no-select" v-loading="loading" element-loading-text="虚拟机生成中..." element-loading-background="#202020")
    ul(v-if="currentVmList.length > 0")
      li(v-for="(item, index) in currentVmList" :key="item.vmName" @click="showVm(index)")
        span
          svg-icon(icon-name="vm")
          span 启动
            span {{ item.formatName }}
</template>

<script>
import {
  UPDATE_SX_INFO
} from 'constants/mutation-types'
import { mapGetters } from 'vuex'
export default {
  name: 'SxList',
  data() {
    return {
      currentVmList: [],
      loading: true,
      qCode: this.$route.query.qCode,
      extendExaminationOperateUrl: '/hdcd/exam/extendExaminationOperate',
      getSxQuestionDetailUrl: '/hdcd/exam/getSxQuestionDetail',
      generateVmUrl: '/hdcd/exam/generateQuestionVm',
      checkGenerateUrl: '/hdcd/exam/checkGenerate',
      exitSxUrl: '/hdcd/exam/exitSxOperate',
      getOperateSxUrl: '/hdcd/exam/getOldSx',
      info: null
    }
  },
  computed: {
    ...mapGetters(['sxCode', 'token', 'vmList', 'uqId'])
  },
  watch: {
    sxCode: {
      immediate: true,
      async handler(val) {
        if (!val) return false
        this.getOperateSx()
      }
    }
  },
  mounted() {
  },
  methods: {
    // 获取实训虚机信息
    getOperateSx() {
      const self = this
      const postData = {
        examCode: self.sxCode,
        phone: self.token
      }
      self.$axios.post(self.getOperateSxUrl, postData).then((res) => {
        if (res.data.success) {
          const result = res.data.result
          if (!result) {
            self.generateVm()
            return false
          }
          var recoveryTime = result.recoveryTime || 0
          switch (result.status) {
            case 'success':
              if (recoveryTime > 0) {
                result.destroyTime = recoveryTime
                self.initVm(result, true)
              } else {
                self.endExamProcess()
              }
              break
            case 'build':
              if (recoveryTime > 0) {
                result.destroyTime = recoveryTime
                self.initVm(result, false)
              } else {
                self.endExamProcess()
              }
              break
            case 'error':
              self.generateVmError()
              break
            default:
              break
          }
        } else {
          if (res.data.message || res.data.msg) {
            self.$message.error(res.data.message || res.data.msg)
          }
        }
      })
    },
    generateVm() {
      const self = this
      const postData = {
        questionCode: this.qCode,
        user: this.token,
        sxCode: this.sxCode
      }
      self.$axios.post(self.generateVmUrl, postData).then((res) => {
        if (res.data.success) {
          const result = res.data.result
          if (result) {
            self.initVm(result, false)
          }
        } else {
          if (res.data.message || res.data.msg) {
            self.$message.error(res.data.message || res.data.msg)
          }
        }
      })
    },
    checkGenerate() {
      const self = this
      const postData = {
        id: self.uqId
      }
      self.$axios.post(self.checkGenerateUrl, postData).then((res) => {
        if (res.data.success) {
          self.info = res.data.result
          if (res.data.result.generateFlag) {
            if (res.data.result.code === '4200') {
              self.vmIds = res.data.result.vmIds
              for (let i = 0; i < self.vmIds.length; i++) {
                self.vmList[i].vmId = self.vmIds[i]
              }
              self.info.vmList = self.vmList
              this.filterVmList()
            } else {
              this.generateVmError()
            }
          } else {
            this.checkGenerateTimeout = setTimeout(() => {
              this.checkGenerate()
            }, 2000)
          }
        } else {
          if (res.data.message || res.data.msg) {
            self.$message.error(res.data.message || res.data.msg)
          }
        }
      })
    },
    // 初始VM信息
    initVm(result, isOldSx) {
      const self = this
      self.info = result
      self.$store.commit(UPDATE_SX_INFO, self.info)
      if (isOldSx) {
        self.filterVmList()
      } else {
        self.checkGenerate()
      }
    },
    // 获去虚拟机列表
    filterVmList() {
      this.currentVmList = []
      for (let i = 0; i < this.vmList.length; i++) {
        if (this.vmList[i].vmId) {
          this.vmList[i].formatName = this.vmList[i].formatName || '虚拟机' + (i + 1)
          this.currentVmList.push(this.vmList[i])
        } else {
          this.generateVmError()
        }
      }
      this.info.vmList = this.vmList
      this.$store.commit(UPDATE_SX_INFO, this.info)
      this.loading = false
      this.$eventBus.$emit('showVmAction')
    },
    generateVmError() {
      this.$notify({
        title: '警告',
        message: '生成虚拟机出错，请联系管理员',
        duration: 0,
        type: 'warning'
      })
    },
    showVm(index) {
      this.$eventBus.$emit('showVmList')
      this.$eventBus.$emit('showVmIndex', index)
    },
    // 结束实训
    endExamProcess() {
      const param = this.$route.query.q
      this.$router.replace({ path: '/', query: param })
    }
  }
}
</script>

<style scoped lang="stylus">
.list
  height 100%
  width 100%
  ul
    text-align center
    width 100%
    li
      font-size 16px
      color #FFFFFF
      cursor pointer
      display inline-grid
      background rgba(#4E6EF2, 0.3)
      border-radius 8px
      line-height 24px
      margin-left 10%
      padding 8px
      span
        display block
      >span
        background-color #4E6EF2
        border-radius 8px
        height 120px
        width 120px
        padding-top 14px
        text-align center
      &:first-child
        margin-left 0
      &:hover
        transform translateY(-5px)
      .svg-icon
        width 36px
        height 36px
        margin-bottom 6px
</style>
