import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);

import Home from '@/views/pusher'
import Help from '@/views/help'
import Sx from '@/views/sx'

var routes = [{
  path: '/',
  name: 'Home',
  component: Home,
  meta: {
    title: '推流 - 华盾播训在线学习平台'
  }
},
{
  path: '/help',
  name: 'Help',
  component: Help,
  meta: {
    title: '学员求助 - 华盾播训在线学习平台'
  }
},
{
  path: '/practice',
  name: 'Sx',
  component: Sx,
  meta: {
    title: '实训 - 华盾播训在线学习平台'
  }
}]
const router = new VueRouter({
  routes,
  mode: 'history'
})

router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  next();
})

export default router
