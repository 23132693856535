<template lang="pug">
div.login-container
  item-card.card-container(title="华盾播训在线学习平台")
    template
      <!-- el-form(ref="loginForm" :model="loginForm" :rules="loginRules" class="login-form" auto-complete="on")
        el-form-item(prop="username")
          span.svg-container
            svg-icon(icon-name="user")
          el-input(
            ref="username"
            v-model="loginForm.username"
            placeholder="请输入用户名"
            name="username"
            type="text"
            auto-complete="on")
        el-form-item(prop="password")
          span.svg-container
            svg-icon(icon-name="password")
          el-input(
            ref="password"
            v-model="loginForm.password"
            placeholder="请输入密码"
            name="password"
            type="password"
            show-password
            auto-complete="on")
      el-button(type="primary" @click.native.prevent="handleLogin") 登 录 -->
      p(style='margin-top: 20px') 直播间参数有误，请联系平台管理员
</template>

<script>
import itemCard from '@/components/common/item-card'
import { getMd5 } from '@/utils/utils'
import { validatePassword } from '@/utils/validate'
import crypto from '@/utils/crypto'
import {
  SET_LIVE_INFO,
  SET_ROOM_ID,
  UPDATE_PUSH_URL,
  UPDATE_IS_LOGIN,
  UPDATE_USER_INFO
} from 'constants/mutation-types'

export default {
  name: 'CompChat',
  components: {
    itemCard
  },
  data() {
    return {
      loginUrl: '/lb/consumer/login',
      infoUrl: '/base/api-saas/info',
      getLivingInfoUrl: '/living/api-saas/getLivingInfo',
      loginForm: {
        username: '',
        password: ''
      },
      loginRules: {
        username: [{ required: true, trigger: 'blur', message: '用户名必填' }],
        password: [{ required: true, trigger: 'blur', validator: validatePassword }]
      }
    }
  },
  created() {
    this.checkParam()
  },
  methods: {
    checkParam() {
      if (!this.$route.query.q) {
        this.$store.commit(UPDATE_IS_LOGIN, false)
        return false
      }
      const param = JSON.parse(crypto.decrypt(this.$route.query.q))
      param.type = param.type || 'normal'
      this.$store.commit(SET_LIVE_INFO, param)
      const protocol = param.pushUrl.split('://')
      param.pushUrl = param.pushUrl.replace(protocol[0], 'webrtc')
      this.$store.commit(UPDATE_PUSH_URL, param.pushUrl)
      document.cookie = 'Admin-Token=' + param.token
      this.getUserInfo(param.token)
      this.getLivingInfo(param.livingCode)
    },
    getUserInfo(token) {
      const self = this
      self.$axios.post(self.infoUrl, { token }).then((res) => {
        if (res.data.success) {
          const info = res.data.result
          const userInfo = {
            userId: token,
            userName: info.name,
            userAvatar: info.avatar,
            userRoles: info.roles
          }
          this.$store.commit(UPDATE_USER_INFO, userInfo)
          this.$store.commit(UPDATE_IS_LOGIN, true)
        } else {
          if (res.data.message || res.data.msg) {
            self.$message.error(res.data.message || res.data.msg)
          }
        }
      })
    },
    // 获取直播间信息
    getLivingInfo(livingCode) {
      const self = this
      const postData = {
        livingCode
      }
      self.$axios.post(self.getLivingInfoUrl, postData).then((res) => {
        if (res.data.success) {
          self.$store.commit(SET_ROOM_ID, res.data.result.roomKey)
        } else {
          if (res.data.message || res.data.msg) {
            self.$message.error(res.data.message || res.data.msg)
          }
        }
      })
    },
    handleLogin() {
      const self = this
      self.$refs.loginForm.validate(valid => {
        if (valid) {
          const postDate = {
            del_flag: 0,
            login_name: self.loginForm.username,
            password: getMd5(self.loginForm.password),
            status: 0
          }
          self.$axios.post(self.loginUrl, postDate).then((res) => {
            if (res.data.success) {
              const date = new Date()
              const expireTime = date.getTime() + 24 * 60 * 60 * 1000
              self.$ls.setLocal('token', res.data.result.token)
              if (res.data.result.userName) {
                self.$ls.setLocal('userName', res.data.result.userName)
              }
              self.$ls.setLocal('token-expire', expireTime)
              location.reload()
            } else {
              if (res.data.message || res.data.msg) {
                self.$message.error(res.data.message || res.data.msg)
              }
            }
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    }
  }
}
</script>

<style lang="stylus" scoped>
.login-container
  align-items center
  display flex
  height calc(100vh - 50px)
  text-align center
  >>>.item-card-container .title-container .title
    opacity 0.7 !important
  .card-container
    padding-bottom 20px
    width 500px
    margin -5vh auto 0 auto
    .login-form
      margin 0 auto
      padding 20px 0 0 0
      width 60%
      >>>.el-input
        display inline-block
        height 47px
        width 85%
      .svg-container
        color #BFC3CB
        vertical-align sub
        margin-right 15px
      >>>.el-form-item__error
        left 38px
      .show-pwd{
        position absolute
        right 13px
        top 4px
      }
</style>

