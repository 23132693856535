<!--
 * @Description: 视频设置弹窗
 * @Date: 2021-10-27 18:13:37
 * @LastEditTime: 2021-11-09 15:48:57
-->
<template lang="pug">
  div.setting-item(v-if="activeTab==='video'")
    div.select-item
      span.title {{ $t('device.Camera') }}
      el-select.select-style(v-model="choseCameraId" :placeholder="$t('device.Select a camera')")
        el-option(
          v-for="item in cameraList"
          :key="item.deviceId"
          :label="item.deviceName"
          :value="item.deviceId"
        )
    div#preview-stream.local-stream-preview(:class="rotateClass" style="display:block")
    el-checkbox.mirror-checkbox(
      v-model="isMirror"
    ) {{ $t('Mirror') }}
    div.select-item
      span.title {{ $t('Resolution') }}
      el-select.select-style(v-model="profileValue" :placeholder="$t('Select a resolution')")
        el-option(
          v-for="item, index in profileList"
          :key="index"
          :label="item"
          :value="item"
        )
    div.select-item
      span.title {{ $t('Frame Rate(fps)') }}
      el-select.select-style(v-model="videoSetting.frameRate" :placeholder="$t('Select a frame rate')")
        el-option(
          v-for="item, index in [15, 20, 25, 30]"
          :key="index"
          :label="item"
          :value="item"
        )
    div.select-item
      span.title {{ $t('Bitrate(kbps)') }}
      el-input-number.select-style(
        v-model="videoSetting.bitrate"
        controls-position="right"
        :min="1"
        :max="9000"
        :step="100")
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import {
  UPDATE_ACTIVE_CAMERA,
  UPDATE_SET_MIRROR,
  UPDATE_VIDEO_PROFILE
} from 'constants/mutation-types'
import {
  LIVE_STAGE
} from 'constants/room'
const profileList = [
  '160*120',
  '320*180',
  '320*240',
  '640*360',
  '640*480',
  '1280*720',
  '1920*1080',
  '2560*1440',
  '3840*2160'
]
export default {
  name: 'CompVideoSetting',
  props: {
    activeTab: String
  },
  data() {
    return {
      LIVE_STAGE,
      cameraList: [],
      choseCameraId: '',
      profileList,
      profileValue: '1280*720',
      videoSetting: null,
      isMirror: true,
      deviceManager: this.$livePusher.getDeviceManager(),
      videoEffectManager: this.$livePusher.getVideoEffectManager()
    }
  },
  computed: {
    ...mapGetters(['activeVideoId']),
    ...mapState({
      liveStage: 'liveStage',
      videoProfile: 'videoProfile',
      videoStreamId: 'videoStreamId',
      isSetMirror: 'isSetMirror'
    }),
    rotateClass() {
      return this.isMirror ? 'rotateY-180' : 'rotateY-0'
    }
  },
  watch: {
    choseCameraId(val) {
      const choseCameraDevice = this.cameraList.find(item => item.deviceId === val)
      if (!choseCameraDevice) {
        return false
      }
      this.$store.commit(UPDATE_ACTIVE_CAMERA, choseCameraDevice)
      this.deviceManager.switchCamera(val, this.videoStreamId).then(() => {
        this.initVideo()
      })
    },
    videoProfile: {
      immediate: true,
      handler(val, oldVal) {
        if (!oldVal && val) {
          this.videoSetting = val
          this.profileValue = `${val.width}*${val.height}`
        }
      }
    },
    isSetMirror: {
      immediate: true,
      handler(val) {
        this.isMirror = val
      }
    },
    videoSetting: {
      deep: true,
      immediate: true,
      async handler(val) {
        await this.$store.commit(UPDATE_VIDEO_PROFILE, val)
        await this.$livePusher.setProperty('setVideoResolution', { width: val.width, height: val.height })
        await this.$livePusher.setProperty('setVideoFPS', val.frameRate)
        await this.$livePusher.setProperty('setVideoBitrate', val.bitrate)
        this.$eventBus.$emit('mixEffect')
      }
    },
    activeTab: {
      immediate: true,
      handler(val, oldVal) {
        if (val === 'video') {
          this.initVideo()
        }
        if (oldVal === 'video') {
          this.resetVideo()
        }
      }
    },
    isMirror(val) {
      this.$store.commit(UPDATE_SET_MIRROR, val)
      this.videoEffectManager.setMirror({
        streamId: this.videoStreamId,
        mirrorType: val ? 1 : 0
      })
    },
    profileValue(val) {
      const [width, height] = val.split('*')
      this.videoSetting.width = parseInt(width, 10)
      this.videoSetting.height = parseInt(height, 10)
    }
  },
  created() {
    this.$nextTick(() => {
      navigator.mediaDevices.addEventListener('devicechange', async() => {
        await this.getDeviceList()
      })
      this.getDeviceList()
      this.choseCameraId = this.activeVideoId
    })
  },
  beforeDestroy() {
    navigator.mediaDevices.removeEventListener('devicechange', this.getDeviceList)
  },
  methods: {
    getDeviceList() {
      this.deviceManager.getDevicesList('video').then((data) => {
        if (data.length > 0 && data[0].deviceId !== '') {
          this.cameraList = data
        } else {
          this.$message.error('请授权摄像头的访问权限')
          this.cameraList = []
          this.choseCameraId = ''
        }
      })
    },
    initVideo() {
      this.$nextTick(() => {
        const mediaStream = this.$livePusher.getMediaStream(this.videoStreamId)
        const node = document.getElementById('preview-stream')
        var video = document.createElement('video')
        node.innerHTML = ''
        node.appendChild(video)
        if ('srcObject' in video) {
          video.srcObject = mediaStream
        } else {
          video.src = URL.createObjectURL(mediaStream)
        }
        video.onloadedmetadata = function(e) {
          video.play()
        }
      })
    },
    resetVideo() {
      const node = document.getElementById('preview-stream')
      node.innerHTML = ''
    }
  }
}
</script>

<style lang="stylus" scoped>
.setting-item
  padding 0 31px 20px 34px
  .select-item
    display flex
    align-items center
    margin-bottom 10px
    .title
      display inline-block
      width 70px
      font-weight 600
      margin-right 10px
    .select-style
      flex-grow 1
  .local-stream-preview
    width fit-content
    height 186px
    border-radius 6px
    overflow hidden
    background-color #212126
    margin 0 auto 10px auto
    text-align center
    & >>> video
      max-height 100%
      max-width 100%
  .rotateY-180
    & >>> video
      transform rotateY(180deg) !important
  .rotateY-0
    & >>> video
      transform rotateY(0deg) !important
  .mirror-checkbox
    margin-bottom 10px
</style>

<i18n>
{
	"en": {
		"Resolution": "Resolution",
		"Select a resolution": "Select a resolution",
		"Frame Rate(fps)": "Frame Rate(fps)",
    "Select a frame rate": "Select a frame rate",
    "Bitrate(kbps)": "Bitrate(kbps)",
    "Mirror": "Mirror"
	},
	"zh": {
		"Resolution": "分辨率",
		"Select a resolution": "请选择分辨率",
		"Frame Rate(fps)": "帧率(fps)",
    "Select a frame rate": "请选择帧率",
    "Bitrate(kbps)": "码率(kbps)",
    "Mirror": "翻转镜像"
	}
}
</i18n>
