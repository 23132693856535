<template lang="pug">
  item-card(title="学员求助")
    div.help-container
      el-switch.btn-control(v-model="canHelp" @change="controlHelp" active-color="#006EFF" :active-text="canHelp ? '开启':'关闭'")
      el-button.btn-all(type="primary" size="mini" @click="goToAllList") 查看在线列表
      el-button.btn-sx(type="primary" size="mini" @click="getQuestionList") 同步实训
      transition(name="el-zoom-in-top")
        el-button.notice(v-show="this.helpCount > 0" type="text" @click="goToHelpList")
          el-tag(type="danger" effect="dark" size="mini") 求助
          span 有{{helpCount}}位学员正在求助
          i.el-icon-arrow-right.el-icon--right
    el-dialog.dialog-style-title(title="实训题目" :visible.sync="dialogVisible")
      div.questionList
        template(v-for="item in questionList")
          el-button(type="primary" size="mini" @click="goToSx(item.questionCode)") {{ item.questionName }}
</template>

<script>
import itemCard from '@/components/common/item-card'
import { mapState, mapGetters } from 'vuex'
export default {
  name: 'HelpSetting',
  components: {
    itemCard
  },
  data() {
    return {
      checkIsHelpUrl: '/living/api-saas/checkIsHelp',
      getHandUpCountsUrl: '/msg/api-saas/getHandUpCounts',
      controlHandUpUrl: '/msg/api-saas/controlHandUp',
      getSxQuestionCollectionUrl: '/hdcd/exam/getSxQuestionCollection',
      canHelp: false,
      helpCount: 0,
      checkCount: null,
      dialogVisible: false,
      questionList: []
    }
  },
  mounted() {
    const self = this
    setTimeout(function() {
      self.getCanHelp()
    }, 100);
  },
  computed: {
    ...mapState(['roomId', 'livingInfo']),
    ...mapGetters(['sxCode'])
  },
  beforeDestroy() {
    clearInterval(this.checkCount)
  },
  methods: {
    // 是否允许求助
    getCanHelp() {
      const self = this
      self.$axios.post(self.checkIsHelpUrl, { 'roomKey': self.roomId }).then((res) => {
        if (res.data.success) {
          self.canHelp = res.data.result
          if (self.canHelp) {
            self.intervalGetHelpCounts()
          } else {
            self.getHelpCounts()
          }
        } else {
          if (res.data.message || res.data.msg) {
            self.$message.error(res.data.message || res.data.msg)
          }
        }
      })
    },
    // 获取求助人数
    getHelpCounts() {
      const self = this
      self.$axios.post(self.getHandUpCountsUrl, { 'roomKey': self.roomId }).then((res) => {
        if (res.data.success) {
          self.helpCount = res.data.result
        } else {
          if (res.data.message || res.data.msg) {
            self.$message.error(res.data.message || res.data.msg)
          }
        }
      })
    },
    // 设置是否允许求助
    controlHelp() {
      const self = this
      self.$axios.post(self.controlHandUpUrl, { 'roomKey': self.roomId, 'openFlag': self.canHelp }).then((res) => {
        if (res.data.success) {
          if (self.canHelp) {
            self.intervalGetHelpCounts()
          } else {
            clearInterval(self.checkCount)
          }
        } else {
          if (res.data.message || res.data.msg) {
            self.$message.error(res.data.message || res.data.msg)
          }
        }
      })
    },
    // 每三秒更新求助人数
    intervalGetHelpCounts() {
      const self = this
      self.getHelpCounts()
      self.checkCount = setInterval(() => {
        self.getHelpCounts()
      }, 3000)
    },
    // 查看在线学员列表
    goToAllList() {
      const param = this.$route.query
      param.type = 'all'
      const routeUrl = this.$router.resolve({ path: '/help', query: param })
      window.open(routeUrl.href, '_blank')
    },
    // 查看求助学员列表
    goToHelpList() {
      const param = this.$route.query
      param.type = 'help'
      const routeUrl = this.$router.resolve({ path: '/help', query: param })
      window.open(routeUrl.href, '_blank')
    },
    // 教师端实训
    goToSx(qCode) {
      this.dialogVisible = false
      const param = this.$route.query
      param.qCode = qCode
      const routeUrl = this.$router.resolve({ path: '/practice', query: param })
      window.open(routeUrl.href, '_blank')
    },
    // 获取题目列表
    getQuestionList() {
      const self = this
      self.$axios.post(self.getSxQuestionCollectionUrl, { 'examCode': self.sxCode }).then((res) => {
        if (res.data.success) {
          self.questionList = res.data.result.questionCollectionList
          this.$nextTick(() => {
            self.dialogVisible = true
          })
        } else {
          if (res.data.message || res.data.msg) {
            self.$message.error(res.data.message || res.data.msg)
          }
        }
      })
    }
  }
}
</script>

<style lang="stylus" scoped>
  >>>.el-dialog
      margin-top 30vh !important
      width 500px
      .el-button
        margin-bottom 10px
  .help-container
    text-align left
    padding 20px
    position relative
    .btn-control
      position absolute
      top -35px
      right 20px
    >>>.el-switch__label
      color white
      padding-top 1px
    .btn-all
      float right
    .notice
      color white !important
      margin-top 15px
      width 100%
      text-align left
      .el-tag
        margin-right 10px
</style>
