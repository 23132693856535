<template>
  <div id="app">
    <router-view v-if="isRouterAlive" />
  </div>
</template>

<script>
export default {
  name: 'App',
  provide() {
    return {
      reload: this.reload
    }
  },
  data() {
    return {
      isRouterAlive: true
    }
  },
  mounted() { },
  methods: {
    reload() {
      this.isRouterAlive = false
      this.$nextTick(() => (this.isRouterAlive = true))
    }
  }
}
</script>
<style lang="stylus">
@import '~assets/style/black-element-ui.styl';
#app
  background-color $backgroundColor
  font-family Avenir, Helvetica, Arial, sans-serif
  --webkit-font-smoothing antialiased
  -moz-osx-font-smoothing grayscale
  text-align center
  width 100vw
  height 100vh
  position relative
  color $fontColor
  div#header
    width 100%
    height 50px
    background-color $backgroundColor
    box-shadow: 0 1px 3px 0 rgba(0,0,0,0.20);
  div#content
    position absolute
    top 50px
    width 100%
    left 0
    bottom 0
    display flex
    background-color $backgroundColor
    div#left
      width 300px
      height calc(100vh - 50px)
      background-color $themeColor
    div#center
      height 100%
      flex-grow 1
      height calc(100vh - 50px)
      width calc(100% - 300px)
    div#right
      width 20%
      min-width 300px
      max-width 406px
      height 100%
      background-color $themeColor

//重置loading控件
.el-loading-mask
  .el-loading-spinner
    margin-top -71px
    svg.circular
      display none !important
    .el-loading-text::before
        content ''
        display block
        width 99px
        height 100px
        margin:0 auto 15px auto
        background url('/loading.gif')
        background-size: cover
  .el-loading-text
    color #fff !important
</style>

