<template lang="pug">
  el-dialog.dialog-style-title(
    :visible.sync="showPushDialog"
    width="460px"
    :before-close="handleClose"
    :close-on-click-modal="false"
    class="dialog-style"
  )
    div.dialog-title(slot="title") 推流设置
    div.dialog-content
      el-form(:model="pushForm" ref="pushForm" :rules="rules" hide-required-asterisk)
        el-form-item(label="推流地址" prop="setPushUrl")
          el-input(v-model="pushForm.setPushUrl" clearable placeholder="webrtc://")
    div.dialog-footer(slot="footer")
      el-button(type="primary" @click="handleSure") {{ $t('common.Save') }}
</template>

<script>
import { mapState } from 'vuex'
import {
  UPDATE_PUSH_URL
} from 'constants/mutation-types'
export default {
  name: 'RoomPushDialog',
  data() {
    return {
      rules: {
        setPushUrl: [
          { required: true, message: '请输入推流地址', trigger: 'blur' }
        ]
      },
      showPushDialog: false,
      pushForm: {
        setPushUrl: ''
      }
    }
  },
  computed: {
    ...mapState({
      pushUrl: 'pushUrl'
    })
  },
  watch: {
    pushUrl: {
      immediate: true,
      handler(val) {
        this.pushForm.setPushUrl = val
      }
    }
  },
  methods: {
    handleShowPushDialog() {
      this.showPushDialog = true
    },
    handleClose() {
      this.showPushDialog = false
    },
    handleSure() {
      this.$refs['pushForm'].validate((valid) => {
        if (valid) {
          this.handleClose()
          let protocol = this.pushForm.setPushUrl.split('://')
          this.pushForm.setPushUrl = this.pushForm.setPushUrl.replace(protocol[0], 'webrtc')
          this.$store.commit(UPDATE_PUSH_URL, this.pushForm.setPushUrl)
        }
      })
    }
  }
}
</script>

<style lang="stylus" scoped>
.dialog-title
  font-weight bold
  color $fontColor
  font-size 16px
.dialog-content
  padding 0 10px
  text-align left
  >>>.el-form-item__label
    font-weight bold
    color $fontColor
    font-size 16px
    display inline-block
    margin-bottom 5px
  .checkbox-container
    width 100%
    margin-bottom 14px
    .checkbox
      margin-bottom 10px
  .select-container
    .title
      width 60px
      font-weight 500
.dialog-footer
  width 100%
  height 100%
  text-align center
</style>

<i18n>
{
	"en": {
    "Room Settings": "Room Settings",
    "Options": "Options",
    "Camera on": "Camera on",
    "Beauty filters": "Beauty filters",
    "Recording": "Recording",
		"Device Select": "Device Select",
    "Mic": "Mic",
    "Speaker": "Speaker",
    "Camera": "Camera"
	},
	"zh": {
		"Room Settings": "直播间设置",
    "Options": "模式设置",
    "Camera on": "开启摄像头",
    "Beauty filters": "开启美颜",
    "Recording": "开启录制",
    "Device Select": "设备选择",
    "Mic": "麦克风",
    "Speaker": "扬声器",
    "Camera": "摄像头"
	}
}
</i18n>
