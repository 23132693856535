<template lang="pug">
  div.stream-container
    div#stream.stream(ref="stream")
    div.tip-container(v-if="attentionInfo")
      div.tip {{attentionInfo}}
</template>

<script>
import { LIVE_STAGE } from 'constants/room'
import {
  UPDATE_ACTIVE_CAMERA,
  UPDATE_ACTIVE_MICROPHONE,
  UPDATE_AUDIO_STREAM_ID,
  UPDATE_VIDEO_STREAM_ID
} from 'constants/mutation-types'
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'CompStreamPusher',
  data() {
    return {
      LIVE_STAGE,
      deviceManager: this.$livePusher.getDeviceManager(),
      videoEffectManager: this.$livePusher.getVideoEffectManager(),
      audioEffectManager: this.$livePusher.getAudioEffectManager(),
      isMirror: true,
      changeLivingStatusUrl: '/living/api-saas/changeLivingStatus'
    }
  },
  computed: {
    ...mapGetters(['activeVideoId', 'activeAudioId', 'livingCode']),
    ...mapState({
      liveStage: 'liveStage',
      roomId: 'roomId',
      userInfo: 'userInfo',
      isSetMirror: 'isSetMirror',
      isOpenBeauty: 'isOpenBeauty',
      beautyParam: 'beautyParam',
      isAudioMuted: 'isAudioMuted',
      isVideoMuted: 'isVideoMuted',
      isRecordLive: 'isRecordLive',
      videoProfile: 'videoProfile',
      isScreenSharing: 'isScreenSharing',
      screenProfile: 'screenProfile',
      videoStreamId: 'videoStreamId',
      audioStreamId: 'audioStreamId',
      screenStreamId: 'screenStreamId',
      audioLevel: 'audioLevel',
      pushUrl: 'pushUrl'
    }),
    userId() {
      return this.userInfo.userId
    },
    attentionInfo() {
      let attentionInfo = ''
      switch (this.liveStage) {
        case LIVE_STAGE.NOT_STARTED:
          if (this.isVideoMuted && !this.isAudioMuted && this.screenStreamId === '') {
            attentionInfo = this.$t('The camera is turned off.')
          }
          if (this.isVideoMuted && this.isAudioMuted && this.screenStreamId === '') {
            attentionInfo = this.$t('Please turn your camera or mic on.')
          }
          break
        case LIVE_STAGE.ONGOING:
          if (this.isVideoMuted && !this.isAudioMuted && this.screenStreamId === '') {
            attentionInfo = this.$t('Audio streaming')
          }
          if (this.isVideoMuted && this.isAudioMuted && this.screenStreamId === '') {
            attentionInfo = this.$t('Please turn your camera or mic on.')
          }
          break
        case LIVE_STAGE.PAUSED:
          attentionInfo = this.$t('Streaming paused')
          break
        case LIVE_STAGE.ENDED:
          attentionInfo = this.$t('Streaming ended')
          break
        default:
          break
      }
      return attentionInfo
    }
  },
  watch: {
    liveStage(val, oldVal) {
      if (val === LIVE_STAGE.ONGOING && oldVal === LIVE_STAGE.NOT_STARTED) {
        this.doStartLive()
        return
      }
      if (val === LIVE_STAGE.ONGOING && oldVal === LIVE_STAGE.PAUSED) {
        this.doGoOnLive()
        return
      }
      if (val === LIVE_STAGE.PAUSED) {
        this.doPauseLive()
        return
      }
      if (val === LIVE_STAGE.ENDED) {
        this.doStopLive()
      }
    },
    async isVideoMuted(val) {
      if (val) {
        this.$livePusher.stopCamera()
        this.$store.commit(UPDATE_ACTIVE_CAMERA, '')
        this.$store.commit(UPDATE_VIDEO_STREAM_ID, '')
        this.mixEffect()
      } else {
        await this.deviceManager.getDevicesList('video').then((data) => {
          if (data.length > 0 && data[0].deviceId !== '') {
            this.$store.commit(UPDATE_ACTIVE_CAMERA, data[0])
          }
        })
        this.$livePusher.startCamera().then((streamId) => {
          console.log('camera stream id is ' + streamId)
          this.$store.commit(UPDATE_VIDEO_STREAM_ID, streamId)
          this.initStream()
          this.mixEffect()
        }).catch((error) => {
          console.log('start camera error: ' + error.toString())
        })
      }
    }
  },
  async created() {
    this.$livePusher.setProperty('enableAudioAEC', true)
    this.$livePusher.setProperty('enableAudioAGC', true)
    this.$livePusher.setProperty('enableAudioANS', true)
    // 获取设备列表并更新到全局
    await this.deviceManager.getDevicesList('video').then((data) => {
      if (data.length > 0 && data[0].deviceId !== '') {
        this.$store.commit(UPDATE_ACTIVE_CAMERA, data[0])
      }
    })
    await this.deviceManager.getDevicesList('audio').then((data) => {
      if (data.length > 0 && data[0].deviceId !== '') {
        this.$store.commit(UPDATE_ACTIVE_MICROPHONE, data[0])
      }
    })
    await this.$livePusher.setRenderView('stream')
    document.getElementById('stream').getElementsByTagName('video')[0].muted = true
    await this.$livePusher.startCamera().then((streamId) => {
      console.log('camera stream id is ' + streamId)
      this.$store.commit(UPDATE_VIDEO_STREAM_ID, streamId)
    }).catch((error) => {
      this.$message.error(error.toString())
    })
    await this.$livePusher.startMicrophone().then((streamId) => {
      console.log('microphone stream id is ' + streamId)
      this.$store.commit(UPDATE_AUDIO_STREAM_ID, streamId)
    }).catch((error) => {
      this.$message.error(error.toString())
    })

    this.$eventBus.$emit('openPre')
    this.initStream()
    this.mixEffect()
    this.$eventBus.$on('mixEffect', this.mixEffect)
  },
  beforeDestroy() {
    this.$eventBus.$off('mixEffect')
    this.doStopLive()
  },
  methods: {
    initStream() {
      // 服务器重连设置
      this.$livePusher.setProperty('setConnectRetryCount', 5)
      // 设置视频内容提示提升视频编码质量
      // this.$livePusher.setVideoContentHint('detail')
      // 音量设置
      this.audioEffectManager.setVolume(this.audioLevel, this.audioStreamId)
      // 镜像设置
      this.videoEffectManager.setMirror({
        streamId: this.videoStreamId,
        mirrorType: this.isSetMirror ? 1 : 0
      })
      // 滤镜设置
      if (this.isOpenBeauty) {
        this.videoEffectManager.setNormalFilter({
          streamId: this.videoStreamId,
          contrast: this.beautyParam.contrast,
          brightness: this.beautyParam.brightness,
          saturation: this.beautyParam.saturation
        })
      }
      // 混流设置
      this.videoEffectManager.setMixingConfig({
        videoWidth: this.videoProfile.width,
        videoHeight: this.videoProfile.height,
        videoFramerate: this.videoProfile.frameRate,
        backgroundColor: 0x000000
      })
    },
    mixEffect() {
      if (this.videoStreamId === '' && this.screenStreamId === '') {
        this.$livePusher.pauseVideo()
        return false
      }
      const videoW = this.videoProfile.width
      const videoH = this.videoProfile.height
      this.$livePusher.resumeVideo()
      this.videoEffectManager.setMixingConfig({
        videoWidth: videoW,
        videoHeight: videoH,
        videoFramerate: this.videoProfile.frameRate,
        backgroundColor: 0x000000
      })
      switch (true) {
        case (this.videoStreamId !== '') && (this.screenStreamId !== ''):
          this.videoEffectManager.setLayout([{
            streamId: this.screenStreamId,
            x: videoW / 2,
            y: videoH / 2,
            width: videoW,
            height: videoH,
            zOrder: 1
          }, {
            streamId: this.videoStreamId,
            x: 7 / 8 * videoW - 20,
            y: 7 / 8 * videoH - 20,
            width: 1 / 4 * videoW,
            height: 1 / 4 * videoH,
            zOrder: 2
          }])
          break
        case (this.videoStreamId !== '') && (this.screenStreamId === ''):
          this.videoEffectManager.setLayout({
            streamId: this.videoStreamId,
            x: videoW / 2,
            y: videoH / 2,
            width: videoW,
            height: videoH,
            zOrder: 1
          })
          break
        case (this.videoStreamId === '') && (this.screenStreamId !== ''):
          this.videoEffectManager.setLayout({
            streamId: this.screenStreamId,
            x: videoW / 2,
            y: videoH / 2,
            width: videoW,
            height: videoH,
            zOrder: 1
          })
          break
      }
    },
    // 开始直播
    async doStartLive() {
      const self = this
      const params = {
        isOpen: 'Y',
        livingCode: self.livingCode
      }
      this.$livePusher.startPush(this.pushUrl)
        .then(() => {
          console.log('push stream is successful')
          self.$axios.post(self.changeLivingStatusUrl, params).then((res) => {
            if (res.data.success) {
              console.log('change Living Status is successful')
            } else {
              if (res.data.message || res.data.msg) {
                self.$message.error(res.data.message || res.data.msg)
              }
            }
          })
        })
        .catch((error) => {
          console.log(error.name + ': ' + error.message)
        })
    },
    // 暂停直播
    async doPauseLive() {
      this.$livePusher.pauseVideo()
      this.$livePusher.pauseAudio()
      this.$livePusher.stopPush()
    },
    // 继续直播
    async doGoOnLive() {
      this.$livePusher.resumeVideo()
      this.$livePusher.resumeAudio()
      this.$livePusher.startPush(this.pushUrl)
        .then(() => {
          console.log('push stream is successful')
        })
        .catch((error) => {
          console.log(error.name + ': ' + error.message)
        })
    },
    // 停止直播
    async doStopLive() {
      const self = this
      const params = {
        isOpen: 'N',
        livingCode: self.livingCode
      }
      self.$axios.post(self.changeLivingStatusUrl, params).then((res) => {
        if (res.data.success) {
          console.log('change Living Status is successful')
        } else {
          if (res.data.message || res.data.msg) {
            self.$message.error(res.data.message || res.data.msg)
          }
        }
      })
      this.$livePusher.stopPush()
      this.$livePusher.stopCamera()
      this.$livePusher.stopMicrophone()
      this.$livePusher.stopScreenCapture()
    }
  }
}
</script>

<style lang="stylus" scoped>
.stream-container
  background-color #000000
  overflow hidden
  .stream
    width 100%
    height 100%
    position absolute
    display flex
    align-items center
    justify-content center
    >>> video
      width 100%
      height 100%
  .tip-container
    position absolute
    width 100%
    height 100%
    background-color #000000
    color $fontColor
    font-weight bold
    font-size 18px
    letter-spacing 1px
    .tip
      position absolute
      top 40%
      left 50%
      transform translateX(-50%)
    .loading
     width 10px
     overflow hidden
</style>

<i18n>
{
	"en": {
    "The camera is turned off.": "The camera is turned off.",
    "Please turn your camera or mic on.": "Please turn your camera or mic on.",
		"Audio streaming": "Audio streaming",
    "Streaming paused": "Streaming paused",
    "Streaming ended": "Streaming ended"
  },
	"zh": {
    "The camera is turned off.": "摄像头已关闭",
    "Please turn your camera or mic on.": "请打开摄像头和麦克风",
		"Audio streaming": "语音直播中",
    "Streaming paused": "直播暂停中",
    "Streaming ended": "直播已结束"
	}
}
</i18n>
