<template lang="pug">
  el-tabs(v-model="activeTab" tab-position="left" type="card")
    el-tab-pane(v-if="textSourceDataList" label="目录" name="menu")
      ul.textSourceMenu
        li(v-for="(item,index) in textSourceDataList" :key="index" :class="{active: index===activeTextSource}" @click="selectTextSource(index)")
          svg-icon(icon-name="file")
          span {{ item.textSourceName }}
    el-tab-pane(v-if="textSourceDataList.length>0" label="详情" name="detail")
      div.textSourceDetail(v-html="textSourceDataList[activeTextSource].content")
    el-tab-pane(label="讨论" name="chat")
      comp-chat
</template>

<script>
import compChat from '@/components/comp-chat'
import { mapGetters } from 'vuex'
export default {
  name: 'SxDetail',
  components: {
    compChat
  },
  props: {
    textSourceDataList: {
      type: Array,
      default: function() {
        []
      }
    }
  },
  data() {
    return {
      activeTab: 'chat',
      activeTextSource: 0
    }
  },
  computed: {
    ...mapGetters(['sxCode'])
  },
  mounted() {
  },
  methods: {
    selectTextSource(index) {
      this.activeTextSource = index
      this.activeTab = 'detail'
    }
  }
}
</script>

<style lang="stylus" scoped>
  article, aside, blockquote, body, button, dd, details, div, dl, dt, fieldset, figcaption, figure, footer, form, h1, h2, h3, h4, h5, h6, header, hgroup, hr, input, legend, li, menu, nav, ol, p, section, td, textarea, th, ul
    margin 0
    padding 0
  p
    font-size 12px

  //目录、详情
  .textSourceMenu
    font-size 14px
    list-style none
    li
      cursor pointer
      margin-bottom 20px
      &.active
        color #182C82
      .svg-icon
        height 22px
        width 22px
        margin-right 10px
        vertical-align middle

  .textSourceDetail
    >>>p
      line-height 1.5
      margin-bottom 10px
    >>>ol,
    >>>ul
      margin-left 30px
    >>>li
      margin-bottom 5px
    >>>img
      display block
      width 100%
      height auto
    >>>table
      border 1px solid gray
      border-collapse collapse
      td
        border 1px solid gray
        padding 5px
      p
        margin-bottom 0

  // 重置el-tabs控件
  .el-tabs
    background-color #F1F3F7
    height 100%

    >>>.el-tabs__header
      border none
      margin 0

    >>>.el-tabs__nav-wrap
      background #1c2131

    >>>.el-tabs__nav
      border 0 !important
      padding-top 20px
      padding-right 1px
      height 100%

    >>>.el-tab-pane
      height 100%

    >>>.el-tabs__item
      border 0 !important
      color rgba(255, 255, 255, 0.5)
      width 60px
      padding 0
      text-align center !important
      &:hover
        color #fff
      &.is-active
        background-color rgba(61, 93, 226, 0.4)
        color #fff
      &:nth-child(3)
        position absolute
        bottom 30%

    >>>.el-tabs__content
      color #202020
      height 100%
      overflow-y auto
      .el-tab-pane:not(#pane-chat)
        padding 20px

    >.el-badge__content.is-dot
      border none
      right 3px
      top 12px
</style>
