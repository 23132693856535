<template lang="pug">
  item-card(:title="$t('Screen Sharing')")
    div.screen-share-container
      //- 未开始屏幕分享
      div.share-not-start
        img.screen-share-img(:src="screenShareImg")
        span.info {{ !isSetScreenSharing?$t('Select content to share'):$t('content is sharing') }}
        el-button.button(
          v-if="!isSetScreenSharing"
          @click="startScreenShare"
          type="primary"
          ) {{ $t('Share') }}
        el-button.button(v-else type="primary" @click="stopScreenShare") {{ $t('Stop Sharing') }}

</template>

<script>
import {
  UPDATE_IS_SCREEN_SHARING,
  UPDATE_SCREEN_STREAM_ID
} from '@/constants/mutation-types'
import ItemCard from '@/components/common/item-card'
import screenShareImg from 'assets/img/screenShare.png'
import { LIVE_STAGE } from 'constants/room'
import { mapState } from 'vuex'
export default {
  name: 'CompScreenShare',
  components: {
    ItemCard
  },
  data() {
    return {
      screenShareImg,
      isSetScreenSharing: false,
      videoEffectManager: this.$livePusher.getVideoEffectManager()
    }
  },
  computed: {
    ...mapState({
      liveStage: 'liveStage',
      isScreenSharing: 'isScreenSharing',
      videoProfile: 'videoProfile'
    }),
    isLiveOngoing() {
      return this.liveStage === LIVE_STAGE.ONGOING
    },
    isLivePaused() {
      return this.liveStage === LIVE_STAGE.PAUSED
    }
  },
  watch: {
    isScreenSharing: {
      immediate: true,
      handler(val) {
        this.isSetScreenSharing = val
      }
    },
    isSetScreenSharing(val) {
      if (!val) {
        this.stopScreenShare()
      }
    }
  },
  methods: {
    initScreenShare({ sdkAppId, shareUserId, shareUserSig, streamId, enable = false }) {
      this.sdkAppId = sdkAppId
      this.shareUserId = shareUserId
      this.shareUserSig = shareUserSig
      this.roomId = streamId
      this.enable = enable
    },
    async startScreenShare() {
      this.$livePusher.startScreenCapture(true).then((streamId) => {
        console.log('screen stream id is ' + streamId)
        this.isSetScreenSharing = true
        this.$store.commit(UPDATE_IS_SCREEN_SHARING, true)
        this.$store.commit(UPDATE_SCREEN_STREAM_ID, streamId)
        this.$eventBus.$emit('mixEffect')
      }).catch((error) => {
        console.log('start screen error: ' + error.toString())
      })
    },
    async stopScreenShare() {
      this.$livePusher.stopScreenCapture()
      this.isSetScreenSharing = false
      this.$store.commit(UPDATE_IS_SCREEN_SHARING, false)
      this.$store.commit(UPDATE_SCREEN_STREAM_ID, '')
      this.$eventBus.$emit('mixEffect')
    }
  }
}
</script>

<style lang="stylus" scoped>
.screen-share-container
  width 100%
  padding 20px 10px
  .share-not-start
    width 100%
    height 100%
    display flex
    flex-direction column
    align-items center
    .screen-share-img
      width 114px
      height 102px
    .info
      display inline-block
      margin-top 10px
    .button
      width 80%
      margin-top 10px
  .stream
    width 100%
    height 220px
    position relative
  .share-started
    width 100%
    margin-top 10px
    display flex
    justify-content center
  .share-paused
    width 100%
    height 100px
    line-height 100px
    text-align center
    color $fontColor
    font-weight bold
</style>

<i18n>
{
	"en": {
    "Screen Sharing": "Screen Sharing",
		"Select content to share": "Select content to share",
    "Share": "Share",
    "Change Content": "Change Content",
    "Stop Sharing": "Stop Sharing",
    "Screen sharing paused": "Screen sharing paused"
	},
	"zh": {
    "Screen Sharing": "共享屏幕",
		"Select content to share": "选择你要共享的区域",
    "Share": "开始共享",
    "Change Content": "新的共享",
    "Stop Sharing": "停止共享",
    "Screen sharing paused": "屏幕分享暂停中",
    "content is sharing": "屏幕共享中"
	}
}
</i18n>
